export const SEARCH_CONTRACTORS_LOADING = "app/SEARCH_CONTRACTORS_LOADING";
export const SEARCH_CONTRACTORS_RESULTS = "app/SEARCH_CONTRACTORS_RESULTS";
export const ACTIVE_CONTRACTORS_LOADING = "app/ACTIVE_CONTRACTORS_LOADING";
export const ACTIVE_CONTRACTORS_RESULTS = "app/ACTIVE_CONTRACTORS_RESULTS";
export const INACTIVE_CONTRACTORS_LOADING = "app/INACTIVE_CONTRACTORS_LOADING";
export const INACTIVE_CONTRACTORS_RESULTS = "app/INACTIVE_CONTRACTORS_RESULTS";
export const SEARCH_CONTRACTORS = "app/SEARCH_CONTRACTORS";
export const CHANGE_PAGE = "app/CONTRACTORS_CHANGE_PAGE";
export const CHANGE_PER_PAGE = "app/CONTRACTORS_CHANGE_PER_PAGE";
export const SEARCH_CONTRACTORS_INV = "app/CONTRACTORS_CHANGE_PER_PAGE";
export const GET_OPEN_INVOICE = "app/GET_OPEN_INVOICE";
export const CONTRACTORS_LOADING = "app/CONTRACTORS_LOADING";
export const CONTRACTORS_RESULTS= "app/CONTRACTORS_RESULTS";