import React from "react";
import { useNavigate } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Button
} from "@mui/material";
import dayjs from "dayjs";
import { Toaster } from 'react-hot-toast';
import "../../../../../utils/sweetalert.css"

const PaymentRow = ({ data, refundData }) => {
  const navigate = useNavigate();

  // const handleSubmit = async (e) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     customClass: {
  //       title: "swal-text",
  //       popup: "swal-text"

  //     },
  //     confirmButtonText: 'Yes, Pay Refund'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // axios.get(`http://localhost:8000/api/v1/payments/refund/${data.id}`)
  //       // axios.get(`https://archernational-back-qa.chetu.com/api/v1/payments/refund/${data.id}`)
  //       // axios.get(`https://archernational-back-uat.chetu.com/api/v1/payments/refund/${data.id}`)
  //       axios.get(`https://api.hocportal.online/api/v1/payments/refund/${data.id}`)
  //         .then(res => {
  //           refundData()
  //           Swal.fire({
  //             toast:true,
  //             position:"top-right",
  //             icon:"success",
  //             timer:4000,
  //             title:"Refund Initiated Successfully!",
  //             showConfirmButton:false
  //           })
  //         }).catch(err => {
  //           if (err.response) {
  //             toast.error('Refund unSuccessful!', {
  //               style: {
  //                 fontFamily: ['Open Sans', 'sans-serif']
  //               }
  //             });
  //           }
  //         })
  //     }
  //   })
  // }
  if (data?.status !== "REFUND") {
    return (
      <TableRow >
        <TableCell component="th" scope="row" width="5%" >
          {data?.id}
        </TableCell>
        <TableCell component="th" scope="row" width="10%" >
          {data?.payer_name}
        </TableCell>
        <TableCell component="th" scope="row" width="10%" >
          {data?.description}
        </TableCell>
        <TableCell component="th" scope="row" width="10%" >

          {data?.company_name || "N/A"} </TableCell>
        < TableCell component="th" scope="row" width="10%" >
          $ {data?.amount}
        </TableCell>
        <TableCell component="th" scope="row" width="10%" > {data?.payment_method}
        </TableCell>
        <TableCell component="th" scope="row" width="10%" >
          {`${dayjs(data?.created_at).format("MM/DD/YYYY")}`}
        </TableCell>
        <TableCell component="th" scope="row" width="10%" >
          {data?.status}
        </TableCell>
        <TableCell component="th" scope="row" width="25%" >
          {/* <div style={{ display: "flex", gap: "8px", width: "100%" } > */}
          <Button variant="contained" color="primary" size="small" sx={{ mr: 0.5}}
            onClick={() => navigate(`/app/invoices/${data.invoice_id}`)}
          >
            View
          </Button>
          {/* {
            data?.status === "ISSUED" ? (<span > </span>) : (
              < Button variant="contained"
                color="primary"
                size="small"
                sx={{ backgroundColor: "#dc3545" }}
                onClick={handleSubmit} endIcon={< SendIcon />} >
                Refund
              </Button>)
          }           */}
        </TableCell>
        < Toaster position="top-right" reverseOrder={false}
          toastOptions={{ duration: 5000 }}
        />
      </TableRow >
    )
  } else {
    return (
      < TableRow >
      </TableRow>
    )
  }
};

export default PaymentRow;