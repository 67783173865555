import React, { useEffect, useState } from "react";
import Members from "./Members";
import Property from "./Property";
import JobDetails from "./JobDetails";
import { Box, Button, CircularProgress } from "@mui/material";
import useJobs from "hooks/useJobs";
import Swal from "sweetalert2";
import ".././../../utils/sweetalert.css"
import useAccessControl from "hooks/useAccessControl";

const JobUpdateForm = ({ membership, state, setState, onSubmit }) => {

  const [loading, setLoading] = React.useState(false);
  const { updateJob } = useJobs();
  const { canUpdateJob } = useAccessControl()
  const [job, _setJob] = useState({});
  const setJob = (values) => _setJob({ ...job, changed: true, ...values });

  const setDefaults = () => {
    _setJob({
      id: state.id,
      contact_id: state.contact_id,
      property_id: state.property_id,
      category_id: state.category_id,
      code_id: state.code_id,
      description: state.description,
      contact: state.contact,
      property: state.property,
      membership_id: state.membership_id,
      loaded: true,
      changed: false,
    });
  };

  useEffect(() => {
    setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id]);

  if (!job.loaded) {
    return null;
  }

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    updateJob(job.id, job).then((res) => {
      setJob({ changed: false });
      setState({
        contact_id: job.contact_id,
        property_id: job.property_id,
        category_id: job.category_id,
        code_id: job.code_id,
        description: job.description,
        contact: job.contact,
        property: job.property,
      });
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: 'Job Updated Successfully!',
        showConfirmButton: false,
        icon: "success",
        timerProgressBar: true,
      });
      setLoading(false); // Add this line
      onSubmit && onSubmit();
    }).catch((error) => {
      setLoading(false); // Also add this line in case of an error
      console.error(error);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Members
        members={membership?.members ?? []}
        membershipId= {job.membership_id}
        contact_id={job.contact_id}
        contact={job.contact}
        setState={setJob}
      />
      <Property
        property={job.property ?? {}}
        property_id={job.property_id}
        properties={membership?.properties}
        setState={setJob}
      />
      <JobDetails
        category_id={job.category_id}
        code_id={job.code_id}
        description={job.description}
        setJob={setJob}
      />
        <Box textAlign="center" mt={2}>
          {canUpdateJob() &&
            <Button type="submit" sx={{ mr: 2 }} variant="contained" size="small">
              Update Job
              {loading && (
                <Box pl={2}>
                  <CircularProgress color="success" size={16} />{" "}
                </Box>
              )}
            </Button>
          }
          <Button
            onClick={setDefaults}
            color="secondary"
            variant="contained"
            size="small"
          >
            Cancel
          </Button>
        </Box>
    </form>
  );
};

export default JobUpdateForm;
