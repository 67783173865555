import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./NotFound.css";
import useAuth from "hooks/useAuth";
import Member from "pages/App/Members/Index/components/Members";
import PaperBlock from "components/PaperBlock";
import { Box, Button, Tab } from "@mui/material";
import SearchInput from "components/SearchInput";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DeletedMembers from "pages/App/Members/Index/components/DeletedMembers";
import useMembership from "hooks/useMembership";
import useApplication from "hooks/useApplication";
import useAccessControl from "hooks/useAccessControl";
import AddIcon from "@mui/icons-material/Add";


export default function NotFound(props) {

  const { user } = useAuth();

  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const { canCreateContractor } = useAccessControl();

  const {
    search,
    membershipReducer: { query },
  } = useMembership();

  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Members Directory",
      path: "/app/members",
    });
    // eslint-disable-next-line
  }, []);
  const handleSearch = (value) => {
    search(value);
  };

  useEffect(() => {
    if (user && user.data.role === 'SYSTEM_ADMINISTRATOR') {
      return navigate('/app/users')
      // return navigate('/app/member')
    }
    else if (user && user.data.role === 'SYSTEM_FRIDAY_BILLING_JOB') {
      return navigate('/app/contractors')
      // return navigate('/app/member')
    } else {
      // return navigate('/app/member')
    }
  });

  return (
    <PaperBlock title="Members"
      actions={
        <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          {canCreateContractor() &&
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/members/create")}
            >
              Add Member
            </Button>
          }
        </Box>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Members"
                value="0"
              />
              <Tab
                label="Canceled Members"
                value="1"
              />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Member />
          </TabPanel>
          <TabPanel value="1">
            <DeletedMembers />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
    //  <Layout icon="ion-ios-warning-outline">    
    //  {props.message} 
    //   <section class="page_404">
    //     <div class="container">
    //       <div class="row">
    //         <div class="col-sm-12">
    //           <div class="col-sm-10 col-sm-offset-1  text-center">
    //             <div class="four_zero_four_bg">
    //               <h1 class="text-center">404</h1>
    //             </div>

    //             <div class="contant_box_404">
    //               <h3 class="h2">
    //                 Look like you're lost
    //               </h3>
    //               <p>the page you are looking for not avaible!</p>                 
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </Layout>
  );
}
// NotFound.defaultProps = {
//   title: "404 Page Not Found",
//   message: "The page you are looking for does not exist.",
// };
