import React, { useState } from "react";
import { Typography, MenuItem, Box, Button, CircularProgress } from "@mui/material";
import FormField from "components/FormField";
import { statusList } from "utils/jobStatuses";
import useStyles from "./style-jss";
import Toast from "components/Toast";
import useJobs from "hooks/useJobs";

const JobStatus = ({ job, changeJobStatus, showLabel }) => {
  const [loadingMail, setLoadingMail] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const classes = useStyles();
  const { downloadJobs, emailJob } = useJobs();

  const downloadInvoiceJob = async (id) => {
    const response = await downloadJobs(id);    
    return response.data;
  };

  const downloadJobDocument = async (id) => {
    setDownloadLoader(true); // Set loading to true at the start
    try {
      const blob = await downloadInvoiceJob(id);
      console.log(blob,'blob');
      
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `HOC-Job-${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      Toast.fire({
        title: "PDF Downloaded Successfully",
        icon: "success",
        position: "top-right",
        timer: 3000,
      });
    } catch (error) {
      console.error("Download failed:", error);
      Toast.fire({
        title: "Download Failed",
        icon: "error",
        position: "top-right",
        timer: 3000,
      });
    } finally {
      setDownloadLoader(false); // Reset loading state after completion or failure
    }
  };

  const emailJobFun = (id) => {
    setLoadingMail(true)
    emailJob(id)
      .then((response) => {
        setLoadingMail(false)
        Toast.fire({
          title: response?.data?.message,
          icon: "success",
          position: "top-right",
          timer: 3000,
        });
      })
      .catch((error) => {
        setLoadingMail(false)
        Toast.fire({
          title: error?.response?.data?.message,
          icon: "error",
          position: "top-right",
          timer: 3000,
        });
      });
  }

  return (
    <div>
      {showLabel && (
        <Typography variant="subtitle2" className={classes.actionsLabel}>
          Status
        </Typography>
      )}
      <Box sx={{ mr: 3 }}>
        <Button
          sx={{
            mr: 2, height: "40px", backgroundColor: '#FC6736', color: 'white', '&:hover': {
              backgroundColor: '#FC6736', // Keeps the background color unchanged on hover
            }
          }}
          variant="contained"
          onClick={() => emailJobFun(job.id)}
        >
          Email Job
          {loadingMail && (
            <Box pl={2}><CircularProgress size={16} /> </Box>
          )}
        </Button>
        <Button
          sx={{
            mr: 2, height: "40px", backgroundColor: '#2196f3', color: 'white', '&:hover': {
              backgroundColor: '#2196f3', // Keeps the background color unchanged on hover
            }
          }}
          variant="contained"
          onClick={() => downloadJobDocument(job.id)}
        >
          Download Job
          {downloadLoader && (
            <Box pl={2}><CircularProgress size={16} /></Box>
          )}
        </Button>
        <FormField
          select
          fullWidth={false}
          value={job.status}
          className={classes.whited}
          classes={{ root: classes[job.status] }}
          onChange={(e) => changeJobStatus(e.target.value)}
        >
          {job.status === "NEW" && <MenuItem value={"NEW"}>NEW</MenuItem>}
          {statusList.map((status) => (
            <MenuItem key={`status-${status}`} value={status}>
              {status}
            </MenuItem>
          ))}
        </FormField>
      </Box>
    </div>
  );
};

JobStatus.defaultProps = {
  showLabel: true,
};

export default JobStatus;
