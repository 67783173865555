import React, { useEffect, useState } from "react";
import useApplication from "hooks/useApplication";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  CircularProgress,
  Box
} from "@mui/material";
import PaymentRow from "./components/PaymentRow";
import usePayments from "hooks/usePayments";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import axios from "axios";

export default function Refund() {
  const { setPage } = useApplication();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    searchPayments,
    changePage,
    changePerPage,
    paymentsReducer: { search, query },
  } = usePayments();
  const [queryState, setQueryState] = useState({
    search: "",
    page: 0,
    per_page: 10
  })

  useEffect(() => {
    setPage({
      title: "payments",
      path: "/app/payments",
    });
    searchPayments(query);
    getData({
      search,
      page: queryState.current_page,
      per_page: queryState.per_page
    });
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getData({
      search: query,
      page: newPage + 1,
      per_page: queryState.per_page
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getData({
      search: query,
      page: queryState.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const getData = async (params) => {
    setLoading(true)
    // const { data } = await axios.get(`http://localhost:8000/api/v1/refund`, {
    // const { data } = await axios.get(`https://archernational-back-uat.chetu.com/api/v1/refund`, {
    const { data } = await axios.get(`https://api.hocportal.online/api/v1/refund`, {
    // const { data } = await axios.get(`https://archernational-back-qa.chetu.com/api/v1/refund`, {

      params
    });
    setQueryState(data)
    setResults(data?.data);
    setLoading(false)
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  const sortedArray = results?.sort((a, b) => b?.id - a?.id);

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (

       <Box sx={{overflow: "auto"}}>
         <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#ID</TableCell>
              <TableCell>Payer name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Company name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Payment type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right" sx={{padding: '6px 31px'}} >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedArray?.map((payment) => (
              <PaymentRow data={payment} key={payment.id}  />
            ))}
            {!sortedArray?.length && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooterWithPagination
            count={queryState.total}
            rowsPerPage={queryState.per_page}
            page={queryState.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
       </Box>
      )}
    </>
  );
}
