import { Chip } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { membershipColors } from "utils/membership";
import "../../../../../../src/components/Callincoming/index.css";

const NewMembersLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/members/${params.id}`;
    const handleLinkClick = (event) => {
        event.preventDefault();
        navigate(url, { replace: true });
    };
    return (
        <NavLink to="#" onClick={handleLinkClick} className='link'>
            {params.value}
        </NavLink>
    );
};

const columns = (renderAction) => [
    {
        field: 'id',
        headerName: 'ID',
        width: 100,
        renderCell: (params) => <NewMembersLink params={params} />,
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 250,
        editable: true,
        renderCell: (params) => <NewMembersLink params={params} />,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 250,
        editable: true,
    },
    {
        field: 'cell_phone',
        headerName: 'Phone',
        type: 'string',
        width: 250,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        sortable: false,
        width: 300,
    },
    {
        field: "status",
        headerName: "Status",
        type: "string", // not "date", since you're rendering a string value
        editable: false,
        sortable: true,
        width: 200,
        renderCell: (params) => (
            <Chip
                sx={{
                    background: membershipColors[params.value],
                    color: "white",
                }}
                label={params.value}
            />
        ),
    },
    {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        width: 190,
        renderCell: renderAction,
    },
];
export default columns;