import {
  SEARCH_INVOICES_LOADING,
  SEARCH_INVOICES_RESULTS,
  SEARCH_INVOICES,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  DELETED_CHECK_RESULTS,
  GET_OPEN_INVOICE_DATA,
  GET_OPEN_INVOICE
} from "redux/actions/invoicesActions";

const initialState = {
  loading: true,
  search: "",
  results: [],
  deleteCheck: [],
  OpenInvoice:[],
  filter: {
    qbeSent: false,
    qbeNotSent: false
  },
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};
export default function invoices(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_INVOICES_RESULTS:
      return {
        ...state,
        results: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    case DELETED_CHECK_RESULTS: {
      return {
        ...state,
        deleteCheck: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }
    case SEARCH_INVOICES_LOADING:
      return { ...state, loading: action?.loading };
    case SEARCH_INVOICES:
      return {
        ...state,
        search: action?.query?.search,
        per_page: action?.query?.per_page,
        current_page: action?.query?.current_page,
        loading: true
      };
    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    case GET_OPEN_INVOICE_DATA:
      return {
        ...state,
        OpenInvoice: action?.results,
        meta: action?.results?.meta,
        loading: false,
      };
    case GET_OPEN_INVOICE: {
      return {
        ...state,
        openInvoiceContractor: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }
    case CHANGE_PER_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    }
    default:
      return state;
  }
}
