import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Box, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateNote({
  selectedNote,
  onCreate,
  onUpdate,
  cancelEdit,
}) {
  const [loading, setLoading] = React.useState(false);
  const [note, setNote] = React.useState("");

  React.useEffect(() => {
    if (selectedNote) {
      setNote(selectedNote.content);
    }
  }, [selectedNote]);

  const createNote = async () => {
    if (note) {
      setLoading(true);
      await onCreate(note);
      setLoading(false);
      setNote("");
    }
  };

  const updateNote = async () => {
    if (note) {
      setLoading(true);
      await onUpdate(note);
      setLoading(false);
      setNote("");
    }
  };

  const cancelEditNote = () => {
    cancelEdit();
    setNote("");
  };

  return (
    <Box>
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_KEY}
        onEditorChange={(value) => setNote(value)}
        value={note}
        init={{
          // height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic underline backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist | ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          variant="text"
          startIcon={<DeleteIcon />}
          onClick={() => setNote("")}
          disabled={!note}
        >
          Clear note
        </Button>
        <Box>
          {selectedNote && (
            <Button
              variant="text"
              startIcon={<CloseIcon />}
              onClick={cancelEditNote}
              disabled={!note}
              sx={{ mr: 1 }}
            >
              Cancel edit
            </Button>
          )}
          <LoadingButton
            loading={loading}
            loadingposition="start"
            startIcon={<DoneIcon />}
            variant="contained"
            onClick={!selectedNote ? createNote : updateNote}
            disabled={!note}
          >
            {!selectedNote ? "Create Note" : "Update Note"}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
