import React from "react";
import { Typography, Box, Divider, Grid, Button } from "@mui/material";
import useStyles from "./style-jss";
import TaskStatus from "./TaskStatus";

const JobTask = ({ data, handleClose, handleChange, setJob  }) => {
  const classes = useStyles();
  // const { taskable_id: data } = data; 
  
  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        <Typography className={classes?.jobTaskTitle} variant="h6">{`NO. ${
          data?.row?.taskable_id
        } - ${
          data?.row?.code_id
            ? data?.row?.title
            : " Website Job Request"
        }`}</Typography>

        <Box>
          {data?.row?.contact && (
            <>
              <Typography
                paddingTop={1}
                fontWeight="bold"
                fontSize={14}
                color="darkgrey"
                component={"p"}
                variant="caption"
              >
                Member Contact
              </Typography>
              <Divider width={200} />
              <Typography
                pt={1}
                variant="body2"
              >{`${data?.row?.contact}`}</Typography>
              <Typography variant="body2">{`${data?.row?.email}`}</Typography>
              <Typography variant="body2">{`${data?.row?.cell_phone}`}</Typography>
            </>
          )}
          {!data?.contact && data?.draft && (
            <>
              <Typography
                paddingTop={1}
                fontWeight="bold"
                fontSize={14}
                color="darkgrey"
                component={"p"}
                variant="caption"
              >
                Primary Contact
              </Typography>
              <Divider width={200} />
              <Typography
                pt={1}
                variant="body2"
              >{`${data?.draft?.first_name} ${data?.draft?.last_name}`}</Typography>
              {data?.draft?.contact_first && (
                <>
                  <Typography
                    paddingTop={1}
                    fontWeight="bold"
                    fontSize={14}
                    color="darkgrey"
                    component={"p"}
                    variant="caption"
                  >
                    Contact Details
                  </Typography>
                  <Divider width={200} />
                  <Typography
                    pt={1}
                    variant="body2"
                  >{`${data?.draft?.contact_first} ${data?.draft?.contact_last}`}</Typography>
                </>
              )}
              <Typography variant="body2">{`${data?.draft?.email}`}</Typography>
              <Typography variant="body2">{`${data?.draft?.phone_no}`}</Typography>
            </>
          )}
        </Box>

        <Box>
          <Typography
            paddingTop={1}
            fontWeight="bold"
            fontSize={14}
            color="darkgrey"
            component={"p"}
            variant="caption"
          >
            Property Address
          </Typography>
          <Divider width={200} />
          {data?.row?.property && (
            <>
              <Typography paddingTop={1} variant="body2">
                {data?.row?.property}
              </Typography>
              <Typography variant="body2">
                {data?.property?.city?.label} {data?.row?.state}{" "}
                {data?.property?.zip}
              </Typography>
            </>
          )}
          {!data?.property && data?.draft && (
            <>
              <Typography paddingTop={1} variant="body2">
                {data?.draft?.service_address}
              </Typography>
              <Typography variant="body2">{data?.draft?.zip}</Typography>
            </>
          )}
        </Box>
        <Box>
          <Typography
            paddingTop={1}
            fontWeight="bold"
            fontSize={14}
            color="darkgrey"
            component={"p"}
            variant="caption"
          >
            Job Details
          </Typography>
          <Divider width={200} />
          <Typography paddingTop={1} variant="body2">
            {data?.row?.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      <Grid item sx={{ mt: 3 }} sm={12} md={12}>
        {data?.row?.status === "PROCESSING" && data?.row?.task_type === "JOB_REQUEST" && (
          <Box
            mt={3}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button onClick={() => setJob(data)} variant="contained">
              Go To Job Sheet
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default JobTask;
