import {Box,} from "@mui/material";
import {
  BillingDetails,
  BillingTable,
  AdditionalFields,
} from "pages/App/Batch/Process/components";
import { useEffect } from "react";
import { DISCOUNT_TYPE_FIXED_AMOUNT, DISCOUNT_TYPE_PERCENTAGE } from "utils/constants";
import { calculateSum } from "utils/functions";

const Invoice = ({
  invoice,
  onChange,
  withActions,
  errors,
}) => {
  const handleChangeItems = (items) => {
    onChange({
      ...invoice,
      items
    })
  };

  const handleSaveNewService = (serviceData) => {
    const newItems = invoice.items;
    newItems.push({
      ...serviceData,
      invoice_batch_invoice_id: invoice.id
    });
    onChange({
      ...invoice,
      items: newItems
    });
  };

  const handleDeleteServices = () => {
    onChange({
      ...invoice,
      items: []
    })
  };

  const handleChangeDetails = (data) => {
    onChange({
      ...invoice,
      ...data
    })
  };

  const handleDeleteService = (serviceIndex) => {
    if (invoice.items[serviceIndex]) {
      onChange({
        ...invoice,
        items: invoice.items.filter((_item, index) => index !== serviceIndex)
      });
    }
  };

  useEffect(() => {
    const subTotal = calculateSum(invoice.items, 'amount').toFixed(2);
    let discount = 0;
    if(invoice?.discountType === DISCOUNT_TYPE_PERCENTAGE) {
      discount = subTotal * (invoice?.discountValue / 100);
    } else if (invoice?.discountType === DISCOUNT_TYPE_FIXED_AMOUNT) {
      discount = invoice?.discountValue
    }

    const total = subTotal - discount;

    if(invoice?.total !== total) {
      onChange({
        ...invoice,
        total
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  return (
    <>
      <Box mb={4}>
        <BillingDetails
          invoice={invoice}
          onChange={handleChangeDetails}
          withActions={withActions}
          errors={errors}
        />
      </Box>
      <Box>
        <AdditionalFields
          invoice={invoice}
          withActions={withActions}
          error={errors}
          onChange={handleChangeDetails}
        />
      </Box>
      <Box mb={4}>
        <BillingTable
          services={invoice}
          onChange={handleChangeItems}
          deleteService={handleDeleteService}
          deleteServices={handleDeleteServices}
          saveNewService={handleSaveNewService}
          withActions={withActions}
          error={errors?.items}
        />
      </Box>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            label="Message displayed on invoice"
            minRows={6}
            value={invoice.description}
            onChange={(event) => handleChangeDetails({ description: event.target.value })}
            disabled={!withActions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InvoiceAmount
            invoice={invoice}
            withActions={withActions}
            onChange={handleChangeDetails}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <InvoiceTotal
            invoice={invoice}
            withActions={withActions}
          />
        </Grid>
      </Grid> */}
    </>
  );
};

Invoice.defaultProps = {
  invoice: {},
  withActions: false,
  onChange: () => {},
  setInvoice: () => {},
  onChangeTotal: () => {},
  errors: {},
};

export default Invoice;
