import {
  SEARCH_INVOICES_BATCHES_LOADING,
  SEARCH_INVOICES_BATCHES_RESULTS,
  SEARCH_INVOICES_BATCHES,
  INVOICES_BATCHES_CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/invoicesBatchesActions";

const initialState = {
  loading: true,
  search: "",
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};
export default function invoicesBatches(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_INVOICES_BATCHES_RESULTS:
      return {
        ...state,
        results: action.results.result,
        meta: action.results.meta,
        loading: false,
      };
    case SEARCH_INVOICES_BATCHES_LOADING:
      return { ...state, loading: action.loading };
    case SEARCH_INVOICES_BATCHES:
      return {
        ...state,
        search: action?.query?.search,
        per_page: action?.query?.per_page,
        current_page: action?.query?.current_page,
        loading: true
      };
    case INVOICES_BATCHES_CHANGE_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    }
    case CHANGE_PER_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    }
    default:
      return state;
  }
}
