import CheckBlock from 'components/CheckBlock'
import useApplication from 'hooks/useApplication';
import CheckForm from 'pages/App/Contractors/components/CheckForm'
import React, { useEffect } from 'react'
import { updateCheckRequest } from 'api/check';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CreateCheck = () => {
    const { setPage } = useApplication();
    const navigate = useNavigate();

    useEffect(() => {
        setPage({
            title: "Add Check",
            path: "/app/invoices",
        });
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (values) => {
        const checkId = localStorage.getItem('checkForm');

        try {
            const response = await updateCheckRequest(values, checkId);
            if (response.data) {
                localStorage.setItem('checkForm', response.data.result.id);
                Swal.fire({
                    toast: true,
                    timer: 3000,
                    position: "top-right",
                    title: response.data.message,
                    showConfirmButton: false,
                    icon: "success",
                    timerProgressBar: true,
                }).then((result) => {
                    navigate("/app/invoices");
                });
            }
        } catch (error) {

            Swal.fire({
                icon: "error",
                toast: true,
                position: "top-right",
                title: "Try again later",
                timer: 4000,
                showConfirmButton: false,
                timerProgressBar: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/app/invoices");
                }
            });
        }
    };
    return (
        <CheckBlock title="Add Check Details">
            <CheckForm onSubmit={onSubmit} />
        </CheckBlock>
    )
}
export default CreateCheck;