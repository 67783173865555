import { Button, CircularProgress, Box, Select, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "components/TaskPreview/styles-jss";
import useInvoices from "hooks/useInvoices";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { editJobNumber, getInvoicedata, updateInvoicedata } from "api/invoices";
import dayjs from "dayjs";
import { changeStatus } from "api/contractors";
import UpdateOpenInvoice from "./UpdateOpenInvoice";

const OpenInvoiceData = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [getInvoiceData, setGetInvoiceData] = useState(null);
  const [statuses, setStatuses] = useState({});
  const {
    invoicesReducer: { OpenInvoice, query, loading, meta },
    getOpenInvoice,
    changePage,
    changePerPage,
  } = useInvoices();

  useEffect(() => {
    getOpenInvoice(query);
  }, [query]);

  useEffect(() => {
    if (OpenInvoice && OpenInvoice.length > 0) {
      const initialStatuses = {};
      OpenInvoice.forEach((inv) => {
        initialStatuses[inv.id] = inv.status || "RECEIVED";
      });
      setStatuses(initialStatuses);
    }
  }, [OpenInvoice]);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getOpenInvoice({
      search: query,
      page: newPage + 1,
      per_page: meta ? meta.per_page : 10,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getOpenInvoice({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const transformedResults = (OpenInvoice?.result || [])?.map(
    (openInvoicesData) => ({
      id: openInvoicesData?.id,
      name: openInvoicesData.contractor_data.name,
      total: openInvoicesData?.total,
      created_at: openInvoicesData?.created_at,
      job_number: openInvoicesData?.job_number,
      hoc_inv_num: openInvoicesData?.hoc_inv_num,
      mem_street: openInvoicesData?.mem_street,
      mem_name: openInvoicesData?.mem_name,
      mem_email: openInvoicesData?.mem_email,
      status: openInvoicesData?.status,
    })
  );

  const handleClick = (data) => {
    const mailtoUrl = `mailto:${data}`;
    window.open(mailtoUrl, "_blank");
  };

  const onSubmit = async (values) => {
    try {
      const response = await updateInvoicedata(values.id, values);
      if (response.data) {
        Swal.fire({
          title: "Are you sure?",
          text: "You would like to make these changes to the Open invoice?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            title: "swal-text",
            popup: "swal-text",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              toast: true,
              timer: 4000,
              position: "top-right",
              title: "Open Contractor Invoice Updated Successfully!",
              showConfirmButton: false,
              icon: "success",
              timerProgressBar: true,
            }).then(() => {
              navigate("/app/invoices");
            });
          }
        });
      }
      getOpenInvoice(query);
    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-right",
        icon: "error",
        title: "Something went wrong!",
        timer: 4000,
        showConfirmButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/invoices");
        }
      });
    }
  };

  const handleCellEditCommit = async (newRow) => {
    const newJobNumber = newRow.value;
    const conInvId = newRow.id;
    const data = {
      job_number: newJobNumber,
      con_inv_id: conInvId,
    };
    try {
      const response = await editJobNumber(data);
      if (response.data) {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response.data.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Error updating job number:", error);
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: "Error Updating Job Number",
        showConfirmButton: false,
        icon: "error",
        timerProgressBar: true,
      });
    }
  };

  const editOpenInvoiceHandler = async (id) => {
    try {
      const response = await getInvoicedata(id);
      if (response.data) {
        setGetInvoiceData(response.data);
        setOpen(true); // Open the popup
      }
    } catch (error) {
      console.error("Error retrieving invoice:", error);
    } finally {
      // navigate("/app/invoices");
    }
  };

  const renderAction = (data) => (
    <div>
      <Button
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() =>
          navigate(`/app/viewInvContractorHoc/${data?.id}/${data.id}`)
        }
      >
        View
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => editOpenInvoiceHandler(data?.id)}
      >
        Edit
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => handleClick(data.row.mem_email)}
      >
        Email
      </Button>
    </div>
  );

  const handleChange = (e, id) => {
    const newStatus = e.target.value;
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));
    changeStatus(id, newStatus)
      .then((response) => {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
        getOpenInvoice(query);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: "top-right",
          timer: 4000,
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
        });
      });
  };

  const OpenInvoiceLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/invoices/${params.id}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <a href="#" onClick={handleLinkClick} className="link">
        {params.value}
      </a>
    );
  };

  const columns = (renderAction) => [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      sortable: false,
      width: 60,
      renderCell: (params) => <OpenInvoiceLink params={params} />,
    },
    {
      field: "name",
      headerName: "Contractor name",
      width: 240,
      editable: true,
    },
    {
      field: "mem_name",
      headerName: "Member Name",
      width: 150,
    },
    {
      field: "hoc_inv_num",
      headerName: "Invoice Number",
      width: 140,
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 110,
      editable: true,
    },
    {
      field: "mem_street",
      headerName: "Member Address",
      width: 200,
    },
    {
      field: "total",
      headerName: "Total Price",
      width: 100,
      editable: true,
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 120,
      editable: true,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: true,
      sortable: true,
      width: 170,
      renderCell: (params) => (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={statuses[params?.row?.id] || params?.row?.status}
          onChange={(e) => handleChange(e, params?.row?.id)}
          sx={{ width: "100%" }}
        >
          <MenuItem value="RECEIVED">RECEIVED</MenuItem>
          <MenuItem value="PENDING">PENDING</MenuItem>
          <MenuItem value="HOLD">HOLD</MenuItem>
          <MenuItem value="APPROVED">APPROVED</MenuItem>
          <MenuItem value="PAID">PAID</MenuItem>
          <MenuItem value="NOT APPROVED">NOT APPROVED</MenuItem>
        </Select>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      type: "string",
      sortable: false,
      renderCell: renderAction,
      width: 250,
    },
  ];

  return (
    <>
      {open && (
        <UpdateOpenInvoice
          open={open}
          handleClose={() => setOpen(false)}
          onSubmitHandler={onSubmit}
          form={getInvoiceData}
        />
      )}
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns(renderAction)}
            rows={transformedResults}
            pageSize={meta?.per_page}
            rowsPerPageOptions={[meta?.per_page]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={OpenInvoice?.meta?.total}
            page={meta?.current_page - 1}
            autoHeight
            disableSelectionOnClick
            className={classes.dataGrid}
            onCellEditCommit={handleCellEditCommit}
          />
        </Box>
      )}
    </>
  );
};

export default OpenInvoiceData;
