import { Notes } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { updateCheckRequest } from 'api/check';
import CheckBlock from 'components/CheckBlock';
import useApplication from 'hooks/useApplication';
import useCheckPayment from 'hooks/useCheckPayment';
import CheckForm from 'pages/App/Contractors/components/CheckForm'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const CheckUpdate = () => {
    const [tabValue, setTabValue] = useState("0");
    const { setPage } = useApplication();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const {
    checkReducer: { results },
    getCheck,
  } = useCheckPayment();

  const [Check, setCheck] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPage({
      title: "Update Check",
      path: "/app/invoices",
    });
    if (!results.length) {
      setLoading(true);
      getCheck(id).then((response) => {
        setCheck(response.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(location?.state?.notes) {
      setTabValue("1");
    }
  }, [location]);


    const setCheckForm = () => {
        if (results.find((checkFormData) => checkFormData.id === +id)) {
          return results.find((checkFormData) => checkFormData.id === +id);
        }
        return Check;
      };

      const onSubmit = async (values) => {
        try {
          const response = await updateCheckRequest(values, id);
          if (response.data) {
            Swal.fire({
              toast:true,
              timer:4000,
              position:"top-right",
              title:'Check Updated Successfully!',
              showConfirmButton:false,
              icon:"success",
              timerProgressBar: true,
            }).then((result) => {
              navigate("/app/invoices");
            });
          }
        } catch (error) {
          Swal.fire({
            toast:true,
            position:"top-right",
            icon: "Error!",
            title:error?.response?.data?.message || "Something went wrong!",
            timer: 4000,
            showConfirmButton:false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/app/invoices");
            }
          });
        }
      };

    return (
        <>
            <CheckBlock title="Update Check" loading={loading}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                            <Tab
                                label="Check information"
                                value="0"
                            />
                            {/* <Tab label="Notes" value="1" /> */}
                        </TabList>
                    </Box>
                    <TabPanel value="0">
                        <CheckForm withHeadingLine={false} onSubmit={onSubmit} form={id && setCheckForm()} />
                    </TabPanel>
                    <TabPanel value="1">
                        <Notes
                            notableType="Checks"
                            notableId={id}
                        />
                    </TabPanel>
                </TabContext>
            </CheckBlock>
        </>
    )
}

export default CheckUpdate
