import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import NewEstimates from "./NewEstimates";
import RequestedEstimates from "./RequestedEstimates";
import { useTheme } from "@mui/styles";
import { getEstimatesRequest, sendEstimateRequest } from "api/jobs";
import EstimateModal from "./EstimateModal";
import Swal from "sweetalert2";
import ".././../../../utils/sweetalert.css";

const JobEstimates = ({
  job,
  defaultActiveTab,
  job_id,
  estimate,
  onRefresh,
  hideOpenEstimate,
  onAccepted,
}) => {
  const theme = useTheme();

  const [state, _setState] = useState({
    estimates: [],
    estimates_request: [],
    note: "",
    loadingEstimates: false,
    activeTab: defaultActiveTab,
    loading: true,
    viewEstimate: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const setState = (values) => _setState({ ...state, ...values });

  const handleCloseEstimate = () => {
    setState({ viewEstimate: false });
    // onChange && onChange();
  };

  const requestEstimates = () => {
    setSubmitting(true);
    setState({ loadingEstimates: true });
    sendEstimateRequest(job_id, {
      note: state.note,
      estimates_request: state.estimates_request,
    }).then((res) => {
      onRefresh && onRefresh();
      getEstimatesRequest(job_id).then((res) => {
        setState({
          estimates: res.data,
          loadingEstimates: false,
          activeTab: "estimate",
          estimates_request: [],
          note: "",
        });
      });
      Swal.fire({
        title: "Success",
        text: "Estimate Request submitted",
        customClass: {
          title: "swal-text",
          popup: "swal-text",
        },
        confirmButtonColor: theme.palette.primary.main,
        timer: 2000,
      });
      setSubmitting(false);
    });
  };

  const handleEditContractorMessage = (cid, message) => {
    const contractorIndex = state.estimates_request.findIndex(
      (c) => c.contractor.id === cid
    );
    if (contractorIndex !== -1) {
      const estimatesx = [...state.estimates_request];
      estimatesx[contractorIndex].message = message;
      setState({ estimates_request: estimatesx });
    }
  };

  useEffect(() => {
    getEstimatesRequest(job_id).then((res) => {
      setState({
        estimates: res.data,
        loading: false,
        activeTab: res.data.length > 0 ? "estimate" : "request",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.status]);
  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {state.estimates.length > 0 && (
            <Tabs
              value={state.activeTab}
              onChange={(_e, newVal) => setState({ activeTab: newVal })}
            >
              <Tab label="Estimates" value={"estimate"} />
              {!estimate && <Tab label="Request Estimate" value={"request"} />}
            </Tabs>
          )}
        </Box>
        {state.activeTab === "estimate" && (
          <RequestedEstimates
            hideOpenEstimate={hideOpenEstimate}
            estimates={state.estimates}
            estimate={estimate}
            setView={(index) => setState({ viewEstimate: index })}
          />
        )}
        {state.activeTab === "request" && (
          <NewEstimates
            status={job?.status}
            state={state}
            setState={setState}
            handleSubmit={requestEstimates}
            handleChangeEstimateMessage={handleEditContractorMessage}
            submitting={submitting}
          />
        )}
      </Box>

      {state.viewEstimate !== false && (
        <EstimateModal
          job={job}
          estimate={job.estimate}
          estimates={state.estimates}
          selected={state.viewEstimate}
          handleClose={handleCloseEstimate}
          onAccepted={onAccepted}
        />
      )}
    </>
  );
};

JobEstimates.defaultProps = {
  defaultActiveTab: "estimate",
  onAccepted: () => {},
};

export default JobEstimates;
