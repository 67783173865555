import React from "react";
import { taskTypeLabels } from "utils/queue";
import QModal from "components/QModal";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import {
  JobTask,
  EstimateTask,
  MembershipTask,
  MessageTask,
  MessageToEstimate,
  CheckPaymentTask,
  InvoiceBatch,
  MissedCallTask,
} from "./components";
import Invoice from "./components/Invoice";
const TaskPreview = ({ data, handleClose, handleSwitchToCreateJob, getTasks, setJob, handleChange }) => {
  
  const renderTask = () => {
    switch (data?.row?.task_type) {
      case "JOB_REQUEST":
        return (
          <JobTask
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
          setJob={setJob}
        />
      );
      case "DISPATCH_REQUEST":
      return (
        <JobTask
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
          setJob={setJob}
        />
      );
    case "NEW_MESSAGE_EST":
      return (
        <MessageToEstimate
          mail={data.taskable}
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
          getTasks={getTasks}
        />
      );
    case "INVOICE_BATCH":
      return (
        <InvoiceBatch
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
          getTasks={getTasks}
        />
      );
    case "INVOICE":
      return (
        <Invoice
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
          getTasks={getTasks}
        />
      );
    case "NEW_MESSAGE":
      return (
        <MessageTask
          mail={data.taskable}
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
        />
      );
    case "NEW_ESTIMATE":
      return (
        <EstimateTask
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
        />
      );
    case "CHECK_PAYMENT":
      return (
        <CheckPaymentTask
          data={data}
          handleClose={handleClose}
          handleChange={handleChange}
        />
      );
    case "NEW_MEMBERSHIP_APPLICATION":
      return (
        <MembershipTask
          handleChange={handleChange}
          data={data}
          handleClose={handleClose}
        />
      );
    case "MISSED_CALL":
        return (
          <MissedCallTask data={data} handleChange={handleChange}  handleClose={handleClose} />
        )
              default:
        return null;
    }
  };

  return (
    <QModal
      dialogProps={{
        open: true,
        title: `#${data?.row?.id} / ${taskTypeLabels[data?.row?.task_type]}`,
        subtitle: dayjs(data?.row?.created_at).format("MM/DD/YYYY hh:mm a"),
      }}
      handleClose={handleClose}
    >
      {renderTask()}

      <Button
        variant="contained"
        onClick={handleSwitchToCreateJob}
        style={{ marginTop: "20px" }}
      >
        Create Another Job Request
      </Button>
    </QModal>
  );
};

export default TaskPreview;
