import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import { createVendorRequest } from "api/vendors";
import VendorForm from "./components/Form";
import '../../../../utils/sweetalert.css'

export default function Create() {
  const { setPage } = useApplication();
  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Add Vendor",
      path: "/app/vendors",
    });

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values) => {
    try {
      const response = await createVendorRequest(values);
      if (response.data) {
        Swal.fire({
          toast:true,
          timer:3000,
          position:"top-right",
          title:'Vendor Added Successfully!',
          showConfirmButton:false,
          icon:"success",
          timerProgressBar: true,
        }).then((result) => {
          navigate("/app/vendors");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        toast:true,
        position:"top-right",
        title: error.response.data.message || "Try again later",
        timer: 4000,
        showConfirmButton:false,
        timerProgressBar: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/vendors");
        }
      });
    }
  };

  return (
    <PaperBlock title="Add Vendor">
      <VendorForm onSubmit={onSubmit} />
    </PaperBlock>
  );
}
