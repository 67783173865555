import React, { useState } from "react";
import { Box, Button, CircularProgress, IconButton, InputAdornment, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormField from "components/FormField";
import useUser from "hooks/useUser";
import useStyles from "./styles";
import ".././../utils/sweetalert.css"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Toast from "components/Toast";

export default function ChangePasswordComponent({ user }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [, setSubmitting] = useState(false);
  const { updateUser } = useUser();

  const ChangePasswordValidationSchema = Yup.object().shape({
    current_password: Yup.string()
      .min(6, "Password too Short!")
      .max(255, "Password too Long!")
      .nullable(),
    password: Yup.string().required("This field is required")
      .min(6, "Password too Short!")
      .max(255, "Password too Long!")
      .nullable(),
    password_confirmation: Yup.string()
      .when("password", {
        is: (password) => password?.length > 0,
        then: Yup.string().required("This field is required"),
        otherwise: Yup.string().nullable(),
      })
      .oneOf([Yup.ref("password"), null], "Confirm password does not match with new password"),
  });

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      if (user?.uuid) {
        updateUser(values, user?.uuid).then(res => {
          Toast.fire({
            icon: "success",
            text: "Password Updated Successfully!",
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          resetForm({
            values: {
              ...values,
              password: "",
              password_confirmation: "",
            },
          })
        }).catch(err => {
          Toast.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something went wrong! Try again later.',
            timer: 3000,
          });

        })
      }
    },
  });

  return (
    <Box sx={{ width: { sx: "100%", md: "40%" } }}>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        Change password
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
        {/* <FormField
          type={showPassword1 ? "text" : "password"}
          label="Current Password"
          value={formik.values.current_password}
          onChange={(e) =>
            formik.setFieldValue("current_password", e.target.value)
          }
          error={
            formik.touched.current_password &&
            Boolean(formik.errors.current_password)
          }
          helperText={
            formik.touched.current_password && formik.errors.current_password
          }
          InputProps={
            {endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword1((prevPass) => !prevPass)}
                  >
                    {showPassword1 ? (
                      <VisibilityOff color="secondary" />
                    ) : (
                      <Visibility color="secondary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
        /> */}
        <FormField
          type={showPassword ? "text" : "password"}
          label="New Password"
          value={formik.values.password}
          onChange={(e) => formik.setFieldValue("password", e.target.value)}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={
            {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevPass) => !prevPass)}
                  >
                    {showPassword ? (
                      <VisibilityOff color="secondary" />
                    ) : (
                      <Visibility color="secondary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
        />
        <FormField
          type={showPassword1 ? "text" : "password"}
          label="Confirm new password"
          onChange={(e) =>
            formik.setFieldValue("password_confirmation", e.target.value)
          }
          value={formik.values.password_confirmation}
          error={
            formik.touched.password_confirmation &&
            Boolean(formik.errors.password_confirmation)
          }
          helperText={
            formik.touched.password_confirmation &&
            formik.errors.password_confirmation
          }
          InputProps={
            {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword1((prevPass) => !prevPass)}
                  >
                    {showPassword1 ? (
                      <VisibilityOff color="secondary" />
                    ) : (
                      <Visibility color="secondary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
        />
        <Button type="submit" variant="contained"
          // disabled={ (formik.values.password === ''
          //   && formik.values.password_confirmation === ''
          // )}
          color="primary" sx={{ mt: 2 }}>
          Update password
          {formik.isSubmitting && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={16} color="success"/>
          </Box>
        )}
        </Button>
      </form>
    </Box>
  );
}
