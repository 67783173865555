import React, { useEffect, useState } from "react";
import {
    TableCell,
    Button,
    Box,
    Chip,
    CircularProgress,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Toast from "components/Toast";
import useEstimates from "hooks/useEstimates";

const EstimatesData = () => {
    const navigate = useNavigate();
    const {
        estimatesReducer: {loading, estimates },
        getEstimateData,
    } = useEstimates();

    useEffect(() => {
        getEstimateData();
    }, []); 

    const estimateResults = estimates?.map((estimate) => ({
        id: estimate?.id,
        job_number: estimate?.job_id,
        contractor_name: estimate?.con_name ?? "Unassigned",
        contractor_company: estimate?.con_biz,
        estimate_amount: estimate?.total,
        submission_date: estimate?.created_at,
        status: estimate?.type,
    }));
    
    const renderAction = (data) => (
        <div style={{ display: "flex", gap: "4px", width: "100%" }}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ mr: 2.0 }}
                onClick={() => navigate(`/app/estimates/${data.id}`)}
            >
                View
            </Button>
        </div>
    );

    // Status Chip
    const StatusChip = ({ status }) => {
        let color;
        if (status === "APPROVED") color = "green";
        else if (status === "PENDING") color = "orange";
        else if (status === "REJECTED") color = "red";

        return <Chip label={status} style={{ backgroundColor: color, color: "white" }} />;
    };

    const columns = [
        { field: "id", headerName: "Estimate ID", width: 150 },
        { field: "job_number", headerName: "Job Number", width: 150 },
        { field: "contractor_name", headerName: "Contractor Name", width: 200 },
        { field: "contractor_company", headerName: "Company Name", width: 200 },
        { field: "estimate_amount", headerName: "Estimate Amount", width: 150 },
        { 
            field: "submission_date", 
            headerName: "Submission Date", 
            width: 150 , 
            renderCell: (params) => dayjs(params.value).format('YYYY-MM-DD hh:mm a'),},
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => <StatusChip status={params?.row?.status} />
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: renderAction,
        },
    ];

    return (
        <>
            {loading ? (
                <CircularProgress style={{ margin: "20px auto", display: "block" }} />
            ) : (
                <Box sx={{ overflow: "auto" }}>
                    <DataGrid
                        columns={columns}
                        rows={estimateResults}
                        autoHeight
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                    />
                </Box>
            )}
        </>
    );
};

export default EstimatesData;
