const SYSTEM_ADMINISTRATOR_ROLE = 'SYSTEM_ADMINISTRATOR';
const EXECUTIVE_ROLE = 'EXECUTIVE';
const userRoles = {
  CUSTOMER_SERVICE: "Customer Service",
  ACCOUNTING: "Accounting",
  EXECUTIVE: "Executive",
  SYSTEM_ADMINISTRATOR: "System Administrator",
};

const DISCOUNT_TYPE_PERCENTAGE = 'PERCENTAGE';
const DISCOUNT_TYPE_FIXED_AMOUNT = 'FIXED_AMOUNT';

export {
  DISCOUNT_TYPE_FIXED_AMOUNT,
  DISCOUNT_TYPE_PERCENTAGE,
  userRoles,
  SYSTEM_ADMINISTRATOR_ROLE,
  EXECUTIVE_ROLE
  };
export const GOOGLE_CLOUD_IMG = 'https://storage.googleapis.com/hoc-site-data/images/';

