import React from "react";
import classNames from "classnames";
import useStyles from "./style-jss";
import { Typography, Paper, CircularProgress } from "@mui/material";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";
import { Box } from "@mui/system";

function ContentBlock(props) {
  const classes = useStyles();
  const {
    title,
    // desc,
    children,
    whiteBg,
    noMargin,
    colorMode,
    overflowX,
    rightActions,
    loading,
    // icon
  } = props;
  return (
    <div>
      <BreadCrumb />
      <Paper
        className={classNames(
          classes.root,
          noMargin && classes.noMargin,
          colorMode && classes.colorMode
        )}
        elevation={2}
      >
        <div className={classes.descBlock}>
          {/* <span className={classes.iconTitle}>
            <i className={icon} />
          </span> */}
          <div className={classes.titleText}>
            <Typography
              variant="h5"
              component="h5"
              fontWeight="bold"
              color="primary"
            >
              {title}
            </Typography>
            {/* <Typography component="p" className={classes.description}>
              {desc}
            </Typography> */}
          </div>
          {rightActions !== null && <div>{rightActions}</div>}
        </div>
        <section
          className={classNames(
            classes.content,
            whiteBg && classes.whiteBg,
            overflowX && classes.overflowX
          )}
        >
          {!loading ? (
            children
          ) : (
            <Box
              p={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
        </section>
      </Paper>
    </div>
  );
}

ContentBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  icon: "ion-ios-bookmark-outline",
  rightActions: null,
  loading: false,
};

export default ContentBlock;
