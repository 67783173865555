import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import "../../../../../utils/sweetalert.css";
import dayjs from "dayjs";
import FormField from "components/FormField";
import { acceptEstimate, getJob } from "api/jobs";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
import QModal from "components/QModal";
const EstimateModal = ({
  job,
  estimates,
  estimate: accepted,
  selected,
  handleClose,
  onAccepted,
}) => {
  const [estimate, setEstimate] = useState(estimates[selected]);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const isAccepted = accepted && accepted.id === estimate.id;

  const handleChange = (name, value) => {
    setEstimate({ ...estimate, [name]: value });
  };

  const displayContractor = () => {
    const { contractor } = estimate;
    return (
      <Box display="flex">
        <Typography fontWeight="bold">{contractor.name}</Typography>
        <Typography pl={4} flexGrow={1} color="gray">
          {contractor.email}
        </Typography>
      </Box>
    );
  };

  const displayEmail = () => {
    const { mailer_email } = estimate;
    return (
      <Box
        mt={2}
        max={200}
        overflow="auto"
        pt={2}
        pb={2}
        pl={1}
        pr={1}
        border="solid 1px #ddd"
        dangerouslySetInnerHTML={{ __html: mailer_email.content }}
      />
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    acceptEstimate({
      id: estimate.id,
      notes: estimate.notes,
      description: estimate.description,
      terms: estimate.terms,
      accepted: estimate.accepted,
      total_cost: estimate.total_cost,
    }).then((res) => {
      onAccepted();
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: !accepted ? "Estimate Accepted Successfully!" : "Estimate Notes Updated",
        showConfirmButton: false,
        icon: "success",
        timerProgressBar: true,
      });
      handleClose();
      // navigate(`/app/members/${job.membership_id}`);
    });
  };

  return (
    <QModal
      dialogProps={{
        open: true,
        title: `Estimate for job No. ${estimate.job_id} - ${job.code.description}`,
        subtitle: ` Date: ${dayjs(estimate.created_at).format("MM/DD/YYYY")}`,
      }}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={9}>
            {estimate.contractor && displayContractor()}
            {estimate.mailer_email && displayEmail()}
            <Box pt={3}>
              {!accepted && job.status === "ESTIMATE" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="accepted"
                      checked={estimate.accepted}
                      onChange={(e) =>
                        handleChange("accepted", e.target.checked)
                      }
                    />
                  }
                  label={"Accept Estimate"}
                />
              )}
            </Box>
            <Box pt={2}>
              <Typography
                fontSize={14}
                mb={1}
                borderBottom="solid 1px lightgray"
                width={150}
                color="gray"
              >
                Notes
              </Typography>
              <FormField
                multiline
                value={estimate.notes}
                onChange={(e) => handleChange("notes", e.target.value)}
                fontSize={12}
                rows={4}
              />
            </Box>
          </Grid>
          <Grid item sm={3}>
            {!accepted && (
              <Button fullWidth variant="contained" disabled>
                Attach File
              </Button>
            )}
            {isAccepted && (
              <>
                <Box mb={2} textAlign="center">
                  <Button variant="outlined" fullWidth textAlign={"center"}>
                    Accepted Estimate
                  </Button>
                </Box>
                <FormField
                  name="accepted_date"
                  label="Accepted Date"
                  type="date"
                  value={estimate?.accepted_date}
                  InputLabelProps={{ shrink: true }}
                  fullWidth={false}
                />
              </>
            )}
          </Grid>
          <Grid item sm={12} textAlign={"center"}>
            {!accepted && (
              <Button
                disabled={
                  !estimate.accepted || loading || job.status !== "ESTIMATE"
                }
                role="submit"
                type="submit"
                variant="contained"
              >
                {loading ? "Please Wait" : "Finalize Acceptance"}
                {loading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="success" size={16} />
                  </Box>
                )}
              </Button>
            )}
            {accepted && (
              <Button
                disabled={!estimate.accepted || loading}
                role="submit"
                type="submit"
                variant="contained"
              >
                Update
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </QModal>
  );
};

export default EstimateModal;
