const TASK_STATUSES = ["OPEN", "PROCESSING", "CLOSED"];

const queueColors = {
  OPEN: "#66bb69",
  PROCESSING: "#ed6c02",
  CLOSED: "#cccccc",
};


const callStausColors = {
  Voicemail: "#ed6c02",
  Accepted:"#66bb69",
  Missed:"#db0f35",
  "Call connected":"#1c742b",
  "Wrong Number":"#fd7e14",
  "Hang Up":"rgb(71 88 195)",
  "No Answer":"#cd422b",
  "Call Failed":"rgb(230 18 18)",
  "IP Phone Offline":"rgb(17 159 223);"
};

const taskTypeLabels = {
  JOB_REQUEST: "Job Request",
  NEW_MEMBERSHIP_APPLICATION: "Member Application",
  NEW_MESSAGE: "New Message",
  NEW_ESTIMATE: "New Estimate",
  NEW_MESSAGE_EST: "New Estimate",
  DISPATCH_REQUEST: "Job Dispatch Request",
  CHECK_PAYMENT: "Check Payment",
  INVOICE_BATCH: "New Invoice Batch",
  INVOICE: "New Invoice",
  MISSED_CALL: "Missed/Rejected Call",
};

export { queueColors, taskTypeLabels, TASK_STATUSES,callStausColors };
