import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Select, MenuItem, Chip, IconButton, Menu, MenuItem as MenuOption, Accordion, AccordionSummary, AccordionDetails, Checkbox, ListItemText } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";

// Mock data for emails
const mockEmails = [
  {
    id: 1,
    subject: "Meeting Reminder",
    email: "meeting@company.com",
    time: "2024-09-25 10:00 AM",
    assignedTo: ["John Doe"],
    status: "To Do",
    thread: [
      { sender: "meeting@company.com", cc: "cc@example.com", bcc: "", body: "Meeting reminder for project discussion on Friday. Please confirm your availability." },
      { sender: "john.doe@company.com", cc: "", bcc: "bcc@example.com", body: "Please confirm your availability." }
    ],
    read: false,
  },
  {
    id: 2,
    subject: "Project Update",
    email: "update@company.com",
    time: "2024-09-24 2:30 PM",
    assignedTo: ["Not Assigned"],
    status: "Acknowledged",
    thread: [
      { sender: "update@company.com", cc: "manager@company.com", bcc: "", body: "Here's the update on the project status. Please review and provide feedback." },
      { sender: "jane.doe@company.com", cc: "", bcc: "", body: "Please review and provide feedback." }
    ],
    read: true,
    hasAttachment: true,
    attachment: "project_update.pdf",
  },
  {
    id: 3,
    subject: "Invoice Approval",
    email: "invoice@company.com",
    time: "2024-09-23 4:15 PM",
    assignedTo: ["Alice Johnson"],
    status: "Needs Attention",
    thread: [
      { sender: "invoice@company.com", cc: "finance@company.com", bcc: "", body: "Invoice #12345 needs approval. Please review and approve it at your earliest convenience." },
      { sender: "alice.johnson@company.com", cc: "", bcc: "", body: "Please review and approve it at your earliest convenience." }
    ],
    read: false,
  },
  {
    id: 4,
    subject: "New User Registration",
    email: "newuser@company.com",
    time: "2024-09-22 9:00 AM",
    assignedTo: ["Not Assigned"],
    status: "Pending",
    thread: [
      { sender: "newuser@company.com", cc: "support@company.com", bcc: "", body: "A new user has registered on the platform." }
    ],
    read: true,
  },
];

// Options for dropdown for assigned people
const peopleOptions = ["Not Assigned", "John Doe", "Jane Smith", "Alice Johnson"];

const statusColors = {
  "To Do": "primary",
  Pending: "warning",
  "Needs Attention": "error",
  Acknowledged: "success",
};

const EmailFeature = () => {
  const { setPage } = useApplication();
  const [selectedEmail, setSelectedEmail] = useState(null); // For dialog box
  const [openDialog, setOpenDialog] = useState(false); // Dialog open/close state
  const [emailData, setEmailData] = useState(mockEmails); // State to handle mock email data with updates
  const [anchorEl, setAnchorEl] = useState(null); // For three-dot menu
  const [menuEmailId, setMenuEmailId] = useState(null); // Tracks which email's menu is open

  useEffect(() => {
    setPage({
      title: "Email",
      path: "/app/email",
    });
  }, [setPage]);

  // Function to handle email row click
  const handleEmailClick = (email) => {
    setSelectedEmail(email);
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmail(null);
  };

  // Function to handle assignment change (multi-select)
  const handleAssignedChange = (id, newAssignedTo) => {
    setEmailData((prevData) =>
      prevData.map((email) =>
        email.id === id ? { ...email, assignedTo: newAssignedTo } : email
      )
    );
  };

  // Open the menu for a specific email
  const handleMenuOpen = (event, emailId) => {
    setAnchorEl(event.currentTarget);
    setMenuEmailId(emailId);
  };

  // Close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuEmailId(null);
  };

  // Toggle the read/unread status
  const toggleReadStatus = (emailId) => {
    setEmailData((prevData) =>
      prevData.map((email) =>
        email.id === emailId ? { ...email, read: !email.read } : email
      )
    );
    handleMenuClose();
  };

  return (
    <>
      <PaperBlock title="Email">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          {/* Table for displaying email list */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>Subject</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Email</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Time</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Assigned To</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Status</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailData.map((email) => (
                  <TableRow
                    key={email.id}
                    hover
                    onClick={() => handleEmailClick(email)}
                    sx={{
                      fontWeight: email.read ? "normal" : "bold", // Bold for unread emails
                      backgroundColor: email.read ? "inherit" : "#f0f0f0", // Light gray for unread emails
                    }}
                  >
                    <TableCell sx={{ textAlign: "center", fontWeight: email.read ? "normal" : "bold" }}>{email.subject}</TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: email.read ? "normal" : "bold" }}>{email.email}</TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: email.read ? "normal" : "bold" }}>{email.time}</TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: email.read ? "normal" : "bold" }}>
                      <Select
                        multiple
                        value={email.assignedTo}
                        onClick={(e) => e.stopPropagation()} // Prevent row click on dropdown interaction
                        onChange={(e) => handleAssignedChange(email.id, e.target.value)}
                        renderValue={(selected) => Array.isArray(selected) ? selected.join(", ") : selected}  // Ensure selected is an array
                        sx={{ minWidth: 120 }} // Making dropdown skinnier
                      >
                        {peopleOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox checked={email.assignedTo.indexOf(option) > -1} />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: email.read ? "normal" : "bold" }}>
                      <Chip
                        label={email.status}
                        color={statusColors[email.status]}
                        variant="outlined"
                        sx={{ borderRadius: "16px" }}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <IconButton
                        aria-label="actions"
                        onClick={(e) => handleMenuOpen(e, email.id)}
                        onMouseDown={(e) => e.stopPropagation()} // Avoid row click when opening menu
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && menuEmailId === email.id}
                        onClose={handleMenuClose}
                      >
                        <MenuOption onClick={() => toggleReadStatus(email.id)}>
                          {email.read ? "Mark as Unread" : "Mark as Read"}
                        </MenuOption>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PaperBlock>

      {/* Dialog for showing detailed email information */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        {selectedEmail && (
          <>
            <DialogTitle>
              {selectedEmail.subject} - {selectedEmail.email}
              <Button variant="contained" color="primary" sx={{ ml: 2 }}>
                Reply
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 2 }}>
                Create Job
              </Button>
            </DialogTitle>
            <DialogContent dividers>
              {selectedEmail?.hasAttachment && (
                <Typography paragraph>
                  <strong>Attachment:</strong> {selectedEmail.attachment}
                </Typography>
              )}
              {selectedEmail.thread.map((message, index) => (
                <Accordion key={index} defaultExpanded={index === 0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{message.body}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <Typography variant="caption">
                        From: {message.sender}, CC: {message.cc}, BCC: {message.bcc}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default EmailFeature;
