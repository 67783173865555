import React, { useEffect } from "react";
import { MenuItem } from "@mui/material";
import FormField from "components/FormField";
import useUtils from "hooks/useUtils";

const StatePicker = ({ ...others }) => {
  const { utils, getStates } = useUtils();

  useEffect(() => {
    if (!utils.statesLoaded) {
      getStates({ all: 1 });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormField select {...others}>
      {utils.states
          .slice()
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((state) => (
            <MenuItem key={others.name + state.code} value={state.code}>
              {state.code}
            </MenuItem>
          ))}
      </FormField>
    </>
  );
};

export default StatePicker;
