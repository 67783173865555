import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Dialog } from "@mui/material";

import PaperBlock from "components/ContentBlock";
import TabContainer from "components/TabContainer";
import FormField from "components/FormField";
import ContactList from "./ContactList";
import { getUsersRequest } from "api/users";
import CloseIcon from "@mui/icons-material/Close";

function ContactWidget({ setShowChildComponent, showChildComponent }) {
  const [state, _setState] = useState({
    contacts: [],
    loading: true,
    tab: 0,
  });
  const setState = (values) => _setState({ ...state, ...values });

  useEffect(() => {

    getUsersRequest().then((res) => {
      setState({
        contacts: res.data.response.result,
        loading: false,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <Fragment>
      <Dialog open={showChildComponent}>
        <Box sx={{ml:"85%", mt:"10px"}}><CloseIcon onClick={() => setShowChildComponent(false)} /></Box>        
        <PaperBlock title="Conference Call" icon="ion-ios-checkbox-outline" noMargin whiteBg>
          {parseInt(state.tab) === 0 && (
            <TabContainer>
              <FormField label="Search Contact"
              />
              {!state.loading && state.contacts.length > 0 && <ContactList setShowChildComponent={setShowChildComponent} results={state.contacts} />}
              {state.contacts.length === 0 && (
                <Box textAlign="center">
                  <Typography fontWeight="bold" fontStyle="italic" variant="body1"> No Data </Typography>
                </Box>
              )}
            </TabContainer>
          )}
        </PaperBlock>
      </Dialog>
    </Fragment>
  );
}

ContactWidget.propTypes = {
  classes: PropTypes.object,
};

export default ContactWidget;
