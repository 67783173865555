import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_JOBS_RESULTS,
  CHANGE_JOB_STATUS,
  NEW_JOBS,
  SEARCH_JOBS_LOADING,
} from "redux/actions/jobsActions";
import {
  changeJobRequest,
  getJobsRequest,
  updateJobRequest,
  changeJobStatusRequest,
  deleteJobRequest,
  downloadJobRequest,
  viewJobRequest,
  emailJobRequest,
  newJobsRequest,
} from "api/jobs";

const useJobs = () => {
  const dispatch = useDispatch();
  const jobsReducer = useSelector((state) => state.jobs);

  const getJobsData = async (params, page, perPage = 10) => {  
    dispatch({ type: SEARCH_JOBS_LOADING, loading: true });  
    try {  
     const res = await getJobsRequest({ ...params }, page, perPage); 
     dispatch({ type: SEARCH_JOBS_RESULTS, results: res?.data });  
    } finally {  
     if (jobsReducer.loading) {  
      dispatch({ type: SEARCH_JOBS_LOADING, loading: false });  
     }  
    }  
  };

  const changeField = (job_id, field, value) => {
    const data = {};
    data[field] = value;
    changeJobRequest(job_id, data);
  };

  const getOpenJobs = debounce((query, page = 1, perPage = 10) => {  
    dispatch({ type: SEARCH_JOBS_LOADING, loading: true });  
    newJobsRequest(query, page, perPage)(dispatch).then((res) => {  
     dispatch({ type: NEW_JOBS, response: res?.data });
    });  
  }, 600);

  const updateJob = (jobId, data) => updateJobRequest(jobId, data);
  const deleteJob = (params) => deleteJobRequest(params);
  const emailJob = (jobId) => emailJobRequest(jobId);
  const downloadJobs = (jobId) => downloadJobRequest(jobId);
  const downloadJobsView = (jobId) => viewJobRequest(jobId)

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const changeJobStatus = (job_id, status) => {
    dispatch({ type: CHANGE_JOB_STATUS, id: job_id, status });
    changeJobStatusRequest({ id: job_id, status });
  };

  return {
    jobsReducer,
    getJobsData,
    changeJobStatus,
    changeField,
    updateJob,
    changePage,
    changePerPage,
    deleteJob,
    downloadJobs,
    downloadJobsView,
    emailJob,
    getOpenJobs
  };
};

export default useJobs;
