import { useDispatch, useSelector } from "react-redux";

import {
    SEARCH_ESTIMATES_RESULTS,
    SEARCH_ESTIMATES_LOADING
  } from "redux/actions/estimateActions";
import {
getAllEstimates
} from "api/estimates";

  const useEstimates = () => {
    const dispatch = useDispatch();
    const estimatesReducer = useSelector((state) => state.estimates);
  
    const getEstimateData = async (params) => {
      dispatch({ type: SEARCH_ESTIMATES_LOADING, loading: true });
      try {
        const res = await getAllEstimates({ ...params });
        dispatch({ type: SEARCH_ESTIMATES_RESULTS, results: res?.data });
      } finally {
        if (estimatesReducer.loading) {
          dispatch({ type: SEARCH_ESTIMATES_LOADING, loading: false });
        }
      }
    };

    return {
        getEstimateData,
        estimatesReducer
      };
  }

export default useEstimates;
