const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 300,
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
    editable: true,
  },
  {
    field: "ext_id",
    headerName: "Ext id",
    width: 300,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    type: "number",
    width: 300,
    editable: true,
  },
];
export default columns;
