import React, { useCallback, useState } from 'react';
import Toast from "components/Toast";
import {Box, Typography} from '@mui/material';
import useUser from 'hooks/useUser';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const MfaSetup = ({
  readOnly,
  users,
  onChangeCallback
}) => {
  const [user] = useState({...users});
  const AntSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4287f5',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  
  const { changeMfa } = useUser();
  const [checked, setChecked] = useState(user?.mfa||user?.mfa_enabled);

  const handleChange = useCallback((e) => {
    changeMfa(e.target.checked, user.id).then(res => {
      setChecked(res.data.user.mfa_enabled);
      onChangeCallback && onChangeCallback(res.data.user.mfa_enabled);
      Toast.fire({
        title: "MFA " + (e.target.checked ? "Disabled" : "Enabled") + "Successfully!",
        icon: "success",
      });
    } );
  }, [changeMfa, user.id, onChangeCallback]);
  return (
    <Box sx={{ width: { sx: "100%", md: "40%" } }}>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        MFA Setup
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
        Enable or disable MFA.
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Disabled</Typography>
        <AntSwitch checked={checked}
            onChange={handleChange} inputProps={{ 'aria-label': 'ant design' }} />
        <Typography>Enabled</Typography>
      </Stack>
      {/* <FormControlLabel
        label={checked ? "Disable MFA" : "Enable MFA"}
        disabled={readOnly}
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
          />
        }
      /> */}

    </Box>
  )
}

MfaSetup.defaultProps = {
  readOnly: false
};

export default MfaSetup;