
import {
    SEARCH_ESTIMATES_RESULTS,
    SEARCH_ESTIMATES_LOADING
} from "redux/actions/estimateActions";
  
  const initialState = {
    loading: false,
    query: "",
    estimates: [],
    meta: {
      current_page: 1,
      from: null,
      last_page: 1,
      per_page: 10,
      to: null,
      total: 0,
    },
  };
  
  export default function estimates(state = initialState, action = {}) {  
    switch (action.type) {
      case SEARCH_ESTIMATES_RESULTS: {
        return {
          ...state,
          estimates: action?.results,
          meta: action?.results?.meta,
          loading: false,
        };
      }
  
      case SEARCH_ESTIMATES_LOADING:
        return { ...state, loading: action.loading };
      default:
        return state;
    }
  }
  