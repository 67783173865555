import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import FormField from "components/FormField";
import useServiceLocations from "hooks/useServiceLocations";
import CityPicker from "components/CityPicker";
import StatePicker from "components/StatePicker";
import ".././../../../utils/sweetalert.css";

const ServiceLocationManagementDialog = ({
  open,
  serviceLocation,
  onClose,
}) => {
  const {
    search,
    serviceLocationsReducer: { query },
    updateServiceLocation,
    createServiceLocation,
  } = useServiceLocations();

  const RolesManagementValidationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string()
      .matches(/^[0-9]{1,5}$/, "Only 5-digit codes are allowed.")
      .max(255)
      .required("Required"),
    city_id: Yup.number().required("Required").nullable(),
    state_code: Yup.string().required("Required").nullable(),
    enabled: Yup.boolean().nullable(),
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      city_id: null,
      enabled: false,
    },
    validationSchema: RolesManagementValidationSchema,
    onSubmit: async (values) => {
      if (serviceLocation?.id) {
        await updateServiceLocation(values, serviceLocation.id).then((resp) => {
          Swal.fire({
            toast: true,
            icon: "success",
            text: "Service location updated",
            timer: 4000,
            customClass: {
              title: "swal-text",
              popup: "swal-text",
            },
            position: "top-end",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          search(query);
          handleClose();
        });
      } else {
        await createServiceLocation(values).then((resp) => {
          Swal.fire({
            toast: true,
            icon: "success",
            text: "Service location created Successfully",
            timer: 4000,
            customClass: {
              title: "swal-text",
              popup: "swal-text",
            },
            position: "top-end",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          search(query);
          handleClose();
        });
      }
    },
  });

  useEffect(() => {
    if (serviceLocation) {
      formik.setValues({
        ...formik.values,
        id: serviceLocation?.id,
        name: serviceLocation?.name,
        city_id: serviceLocation?.city?.id,
        enabled: serviceLocation?.enabled,
        region_code: serviceLocation?.name.split("-")[0],
        city: {
          id: serviceLocation?.city?.id,
          city: serviceLocation?.city?.city,
          label: serviceLocation?.city?.label,
        },
        state_code: serviceLocation?.state_code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceLocation]);

  const setDialogTitle = () => {
    if (serviceLocation) return `Manage service location`;
    return "Create service location";
  };

  const setCityValue = (e) => {
    if (e) {
      formik.setFieldValue("city_id", e?.id);
      formik.setFieldValue("city", {
        id: e?.id,
        city: e?.city,
        label: e?.label,
      });
    } else {
      formik.setFieldValue("city_id", null);
      formik.setFieldValue("city", null);
    }
  };

  if (!open) return null;
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{setDialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <FormField
              label="Code"
              value={formik.values.name.split("-")[0]}
              onChange={formik.handleChange}
              name="name"
              required
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StatePicker
              required
              name="state_code"
              label="State"
              value={formik.values.state_code}
              onChange={(e) =>
                formik.setFieldValue("state_code", e.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(formik.errors.state_code)}
              helperText={formik.errors.state_code}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CityPicker
              label="City"
              required
              InputLabelProps={{ shrink: true }}
              value={formik?.values?.city}
              name="city"
              state_code={formik.values.state_code}
              onChange={(e) => setCityValue(e)}
              error={formik.touched.city_id && Boolean(formik.errors.city_id)}
              helperText={formik.touched.city_id && formik.errors.city_id}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="enabled"
                  checked={formik.values.enabled ? true : false}
                  onChange={(e) =>
                    formik.setFieldValue("enabled", e.target.checked)
                  }
                />
              }
              label="Enabled"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={formik.isSubmitting}
          loadingposition="center"
          variant="contained"
          onClick={formik.submitForm}
        >
          {serviceLocation?.id ? "Update" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ServiceLocationManagementDialog.defaultProps = {
  serviceLocation: null,
  onClose: () => {},
};

export default ServiceLocationManagementDialog;
