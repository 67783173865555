import React from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import FormField from "components/FormField";

const Members = ({ members, membershipId, contact, setState }) => {
  const handleChange = (e) => {
    // eslint-disable-next-line eqeqeq
    const memberIndex = members.findIndex((m) => m.id == e.target.value);
    setState({
      contact_id: e.target.value,
      contact: members[memberIndex],
    });
  };

  return (
    <>
      <Box pb={2} pt={2}>
        <FormField
          required
          label="Member Contact"
          select
          value={contact?.id}
          onChange={handleChange}
        >
          {members.map((member, index) => (
            <MenuItem key={member?.id} value={member?.id}>{`${member?.first_name
              } ${member?.last_name} ${member?.is_primary ? "(Primary)" : ""
              }`}</MenuItem>
          ))}
        </FormField>
      </Box>
      {contact && (
        <Box pl={1} pb={2}>
          <Typography
            gutterBottom
            variant="body2"
            onClick={() => {
              window.open(`/app/members/${membershipId}`, '_blank');
            }}
            sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
          >
            {`${contact.first_name} ${contact.last_name} / (No. ${membershipId})`}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
          >{`${contact.email}`}</Typography>
          <Typography
            gutterBottom
            variant="body2"
          >{`${contact.cell_phone}`}</Typography>
        </Box>
      )}
    </>
  );
};

export default Members;
