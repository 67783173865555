import {
  SEARCH_MEMBERS_LOADING,
  SEARCH_MEMBERS_RESULTS,
  SEARCH_MEMBERS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  DELETED_MEMBERS_RESULTS,
  NEW_MEMBER
} from "redux/actions/membersActions";

const initialState = {
  loading: true,
  query: "",
  results: [],
  deletedMembers: [],
  openMember: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

export default function membership(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_MEMBERS_RESULTS: {
      return {
        ...state,
        results: action.results.result,
        meta: action.results.meta,
        loading: false,
      };
    }
    case DELETED_MEMBERS_RESULTS: {
      return {
        ...state,
        deletedMembers: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }

    case SEARCH_MEMBERS_LOADING:
      return { ...state, loading: action.loading };
    case SEARCH_MEMBERS:
      return { ...state, query: action.query, loading: true };
    case NEW_MEMBER:
      return {
        ...state,
        openMember: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };

    case CHANGE_PER_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    default:
      return state;
  }
}
