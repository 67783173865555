function debounce(fn, delay) {
  let timer;
  return (() => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  })();
}

function parsePhoneNo(phoneNo) {
  if (!phoneNo) {
    return "";
  }
  return phoneNo.replace(/^\+?1?/, "").replace(/[^\d]/g, "");
}

function displayAsPhone(number) {
  return `+1(${number.substring(1, 4)}) ${number.substring(
    4,
    7
  )}-${number.substring(7, 11)}`;
}

function uniqueDeviceId() {
  const id = `${Math.random().toString(36).substr(2, 9)}`;
  const ua = navigator.userAgent;
  const browser = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  const name = browser[1];
  const version = browser[2];
  const os = navigator.platform;
  return `${id}-${name}-${version}-${os}`;
}

function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function calculateSum(array, property) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);

  return total;
}

export {
  calculateSum,
  debounce,
  parsePhoneNo,
  displayAsPhone,
  uniqueDeviceId,
  serialize
};
