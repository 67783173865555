import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_CHECK,
  SEARCH_CHECK_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_CHECK_LOADING,
} from "redux/actions/checkPaymentAction";
import { getCheckRequest, getChecksRequest } from "api/check";
import debounce from "lodash.debounce";

const useCheckPayment = () => {
  const dispatch = useDispatch();
  const checkReducer = useSelector((state) => state.checksPayment);

  const getChecks = (params) => {
    dispatch({ type: SEARCH_CHECK_LOADING, loading: true });
    return getCheckRequest(params)
      .then((res) => {
        dispatch({ type: SEARCH_CHECK_RESULTS, results: res.data });
      })
      .finally(() => {
        if (checkReducer.loading) {
          dispatch({ type: SEARCH_CHECK_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_CHECK, query });
    getChecksRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_CHECK_RESULTS, results: res.data });
    });
  }, 600);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const getCheck = (CheckId) => getCheckRequest(CheckId);
  const updateCheck = (CheckId) => getChecksRequest(CheckId);
  const deleteCheck = (CheckId) => getChecksRequest(CheckId);

  return {
    checkReducer,
    getChecks,
    getCheck,
    updateCheck,
    deleteCheck,
    search,
    changePage,
    changePerPage,
  };
};
export default useCheckPayment;