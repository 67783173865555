import {
  SEARCH_SERVICE_LOCATIONS_LOADING,
  SEARCH_SERVICE_LOCATIONS_RESULTS,
  SEARCH_SERVICE_LOCATIONS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_SERVICE_VIEW_LOCATIONS_RESULTS
} from "redux/actions/serviceLocations";

const initialState = {
  timestamp: null,
  query: "",
  loading: false,
  resultsData: [],
  viewLocation:[],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

export default function serviceLocations(state = initialState, action = {}) {  
  switch (action.type) {
    case SEARCH_SERVICE_LOCATIONS_RESULTS:
      return {
        ...state,
        resultsData: action.results.result,
        meta: action.results.meta,
        loading: false,
      };
    case SEARCH_SERVICE_VIEW_LOCATIONS_RESULTS:
      return {
        ...state,
        viewLocation: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };

    case SEARCH_SERVICE_LOCATIONS_LOADING:
      return { ...state, results: [], loading: action.loading };
    case SEARCH_SERVICE_LOCATIONS:
      return { ...state, results: [], query: action.query, loading: true };
    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    case CHANGE_PER_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    default:
      return state;
  }
}
