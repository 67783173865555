/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";

import PhoneIcon from "@mui/icons-material/Phone";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import useStyles from "./styles";
import useSystem from "WebR/hooks/useSystem";

function ContactList({ setShowChildComponent,results }) {
  const classes = useStyles();
  const [, setAnchorEl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { data, call,hold,callout } = useSystem();

  const callContact = (member, number,nameValue,ConferanceCall) => {
    hold()
    setShowChildComponent(false);
    callout(number, "+1",nameValue,ConferanceCall);
    // handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getItem = (dataArray) =>
    dataArray.map((data) => (
      data.extension !== undefined && data.extension !== '' && (
        <Fragment key={data.id}>
        <ListItem button key={data.id}>
          <ListItemText
            primary={`${data.name}`}
            secondary={`Extension #${data.extension}`}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Call">
              <IconButton
                onClick={handleClick}
                className={classes.tealText}
                aria-label="Tel"
              >
                <PhoneIcon  onClick={() => callContact(data.id, data.extension,data.name,"ConferanceCall")}/>
              </IconButton>
            </Tooltip>
            {/* <Menu
              id={`menu-${data.id}`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {data.cell_phone && (
                <MenuItem onClick={() => callContact(data.id, data.cell_phone)}>
                  Cell Phone: {data.cell_phone}
                </MenuItem>
              )}
              {data.home_phone && (
                <MenuItem onClick={() => callContact(data.id, data.home_phone)}>
                  Home Phone: {data.home_phone}
                </MenuItem>
              )}

              {data.work_phone && (
                <MenuItem onClick={() => callContact(data.id, data.work_phone)}>
                  Home Phone: {data.work_phone}{" "}
                  {data.work_phone_extension
                    ? `ext. ${data.work_phone_extension}`
                    : ""}
                </MenuItem>
              )}
            </Menu> */}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </Fragment>
      )
    ));
  return <List>{getItem(results)}</List>;
}

export default React.memo(ContactList);
