import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import useApplication from "hooks/useApplication";
import PaperBlock from "components/PaperBlock";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RemittanceReport from "../RemittanceReport";

export default function Reports() {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  useEffect(() => {
    setPage({
      title: "Reports Directory",
      path: "/app/reports",
    });
    // eslint-disable-next-line
  }, []);
  return (
    <PaperBlock
      title="Reports"
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab label="Remittance Report" value="0" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <RemittanceReport />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
}
