import React from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import FormField from "components/FormField";
import useQueue from "hooks/useQueue";
import { TASK_STATUSES } from "utils/queue";
import AssignedTo from "components/AssignedTo";
import useStyles from "../styles-jss";
import Toast from "components/Toast";

const TaskStatus = ({ data, handleChange }) => {
  const classes = useStyles();
  const { changeTaskStatus, changeTaskUser, getOpenTask, getTasks} = useQueue();
  

  const isProcessing = () => data?.row?.status === "PROCESSING";
  const hasTypePicker = () =>
    ["NEW_MESSAGE_EST", "NEW_MESSAGE", "INVOICE_BATCH", "INVOICE"].indexOf(
      data?.row?.task_type
    ) !== -1;
    
  return (
    <>
      <Box mt={2}>
        <Typography variant="subtitle2" className={classes.actionsLabel}>
          Status
        </Typography>
        <FormField
          select
          value={data?.status ? data.status : data?.row?.status}
          className={classes.whited}
          classes={{ root: classes[data?.status ? data.status : data?.row?.status] }}
          onChange={(e) => {
            changeTaskStatus(data.id, e.target.value);
           getOpenTask();
           getTasks()
            handleChange &&
              handleChange({
                status: e.target.value,
              });
              
          }}
        >
          {TASK_STATUSES.map((status) => (
            <MenuItem key={`status-${status}`} value={status}>
              {status}
            </MenuItem>
          ))}
        </FormField>
      </Box>
      {isProcessing() && hasTypePicker() && (
        <Box mt={2}>
          <Typography variant="subtitle2" className={classes.actionsLabel}>
            Type
          </Typography>
          <FormField
            select
            value={data.task_type}
            className={classes.whited}
            classes={{ root: classes[data.task_type] }}
            onChange={(e) => handleChange({ task_type: e.target.value })}
          >
            <MenuItem value={"INVOICE"}>INVOICE</MenuItem>
            <MenuItem value={"INVOICE_BATCH"}>INVOICE BATCH</MenuItem>
            <MenuItem value={"NEW_MESSAGE_EST"}>NEW ESTIMATE</MenuItem>
            <MenuItem value={"NEW_MESSAGE"}>NEW MESSAGE</MenuItem>
          </FormField>
        </Box>
      )}
      <Box mt={2}>
        <Typography variant="subtitle2" className={classes.actionsLabel}>
          Assign to
        </Typography>
        <AssignedTo
          value={data?.assigned_to ? data?.assigned_to : data?.row?.assigned_to}
          onChange={(e) => {
            changeTaskUser(data.id, e.target.value);
            // onChange({ assigned_to: e.target.value });
            Toast.fire({
              title: "Assigned To Updated Successfully!",
              icon: "success",
              position: "top-right",
              timer: 3000,
            });            
          }}
        />
      </Box>
    </>
  );
};

export default TaskStatus;
