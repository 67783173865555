import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PaperBlock from "components/PaperBlock";
import useServiceLocations from "hooks/useServiceLocations";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import columns from "./ViewColumnData";

const ViewServiceLocation = () => {
  const { name } = useParams();

  const {
    serviceLocationsReducer: { viewLocation, query, loading, meta },
    getViewServiceLocations,
    changePage,
    changePerPage,
  } = useServiceLocations();

  useEffect(() => {
    getViewServiceLocations(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getViewServiceLocations(name, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getViewServiceLocations({
      search: query,
      page: meta?.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const settingViewResults = Array.isArray(viewLocation)
    ? viewLocation.map((viewData) => ({
        id: viewData?.id,
        name: `${viewData?.first_name} ${viewData?.last_name}`,
        email: viewData?.email,
        ext_id: viewData?.ext_id,
        phone: viewData?.phone,
      }))
    : [];

  return (
    <>
      <PaperBlock title="Settings">
        {loading ? (
          <CircularProgress style={{ margin: "20px auto", display: "block" }} />
        ) : (
          <Box>
            <DataGrid
              columns={columns}
              rows={settingViewResults}
              autoHeight
              pageSize={Number(meta?.per_page)}
              rowsPerPageOptions={[Number(meta?.per_page)]}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              paginationMode="server"
              rowCount={meta?.total}
              page={meta?.current_page - 1}
              disableSelectionOnClick
            />
          </Box>
        )}
      </PaperBlock>
    </>
  );
};

export default ViewServiceLocation;
