import dayjs from "dayjs";

const columns = (renderStatus, renderAction) => [
  {
    field: "id",
    headerName: "Inv#",
    sortable: true,
    type: "string",
    flex: 0.2,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    flex: 2,
  },
  {
    field: "payment_method",
    headerName: "Payment Method",
    type: "string",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "amount",
    headerName: "Amount",
    editable: false,
    type: "number",
    sortable: true,
    flex: 1,
    renderCell: (param) => `$${(param.row.amount)}`,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 1,
    renderCell: renderStatus,
  },
  {
    field: "created_at",
    headerName: "Date Rec",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1.5,
    renderCell: (params) =>
      dayjs(params.row.created_at).format("MM/DD/YYYY hh:mm a"),
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    flex: 0.7,
    sortable: false,
    renderCell: renderAction,
  },
];

export default columns;