import React from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  ListItemText,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ContactsComponent = ({ members }) => {
  return (
    <Box>
      {members.map((member, index) => (
        <Accordion key={`member-${index}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold" color="primary" variant="body2">{`${
              index + 1
            }. ${member.first_name} ${member.last_name}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={4}>
                <ListItemText
                  primary="First name"
                  secondary={member.first_name}
                />
              </Grid>
              <Grid item xs={4}>
                <ListItemText
                  primary="Last name"
                  secondary={member.last_name}
                />
              </Grid>
              <Grid item xs={4}>
                <ListItemText primary="Email" secondary={member.email} />
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={4}>
                <ListItemText primary="Company" secondary={member.company} />
              </Grid>
              <Grid item xs={4}>
                <ListItemText
                  primary="Occupation"
                  secondary={member.occupation}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={4}>
                <ListItemText
                  primary="Cell phone"
                  secondary={member.cell_phone}
                />
              </Grid>
              <Grid item xs={4}>
                <ListItemText
                  primary="Home phone"
                  secondary={member.home_phone || "No phone"}
                />
              </Grid>
              <Grid item xs={4}>
                <ListItemText
                  primary="Work phone"
                  secondary={member.work_phone || "No phone"}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ContactsComponent;
