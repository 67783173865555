import debounce from "lodash.debounce";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    AllcalllogRequest,
} from "../api/connect";

import {
    CHANGE_PER_PAGE_ALL_CALL_LOG,
    SEARCH_MEMBERS_ALL_CALL_LOG,
    SEARCH_MEMBERS_LOADING_ALL_CALL_LOG,
    SEARCH_MEMBERS_RESULTS_ALL_CALL_LOG,
    CHANGE_PER_PAGE
} from "../redux/actions/allCallLogsAction";

const useMembershipshipAllCallLog = () => {
    const dispatch = useDispatch();
    const membershipAllCallLogReducer = useSelector((state) => state.allCallLogs);

    const getMemberships = (params) => {
        dispatch({
            type: SEARCH_MEMBERS_LOADING_ALL_CALL_LOG,
            loading: true
        });
        return AllcalllogRequest({
            ...params
        })
            .then((res) => {
                dispatch({
                    type: SEARCH_MEMBERS_RESULTS_ALL_CALL_LOG,
                    results: res.data
                });
            })
            .finally(() => {
                if (membershipAllCallLogReducer.loading) {
                    dispatch({
                        type: SEARCH_MEMBERS_LOADING_ALL_CALL_LOG,
                        loading: false
                    });
                }
            });
    };

    const search = debounce((query) => {
        dispatch({
            type: SEARCH_MEMBERS_ALL_CALL_LOG,
            query
        });

        // searchRequest({
        //     search: query
        // }).then((res) => {
        //     dispatch({
        //         type: SEARCH_MEMBERS_RESULTS_ALL_CALL_LOG,
        //         results: res.data
        //     });
        // });
    }, 600);


    const changePage = (newPage) => {
        dispatch({ type: CHANGE_PER_PAGE, page: newPage + 1 });
    };

    const changePerPage = (event) => {
        dispatch({
            type: CHANGE_PER_PAGE_ALL_CALL_LOG,
            perPage: parseInt(event.target.value)
        });
    };

    return {
        membershipAllCallLogReducer,
        search,
        changePage,
        changePerPage,
        getMemberships,
    };
};

export default useMembershipshipAllCallLog;