import axios from "utils/axios";
import { GET_USERS_ROUTE, USER_ROUTE } from "./routes";

const getUsersRequest = () => axios.get(GET_USERS_ROUTE);
const getUserRequest = (uuid) => axios.get(`${USER_ROUTE}/${uuid}`);
const createUserRequest = (params) =>
  axios.post(`${USER_ROUTE}/create`, params);
const updateUserRequest = (params, uuid = null) =>
  axios.post(`${USER_ROUTE}/${uuid ? "update/" + uuid : ""}`, params);

const deleteUserRequest = (params, uuid = null) =>
  axios.delete(`${USER_ROUTE}/delete/id?id=${params}`);

const locationRequest = (office_id, data, page, perPage) => 
  axios.post(`${USER_ROUTE}/service_location?office_id=${office_id}`, data,
    {
      params: {
        page,
        per_page: perPage,
      },
    });  

const searchUsersRequest = (search) => {
  return axios.get(GET_USERS_ROUTE, { params: { ...search } });
};

const changeMfaRequest = (params) => axios.post(`${USER_ROUTE}/mfa`, params);

const createRcAccountRequest = (params) =>
  axios.post(`${USER_ROUTE}/rc`, params);

export {
  getUsersRequest,
  getUserRequest,
  createUserRequest,
  searchUsersRequest,
  deleteUserRequest,
  updateUserRequest,
  changeMfaRequest,
  createRcAccountRequest,
  locationRequest,
};
