import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import RolesList from "./components/RolesList";
import RolesManagementDialog from "./components/RolesManagementDialog";
import useUtils from "hooks/useUtils";
import useAccessControl from "hooks/useAccessControl";

const Roles = () => {
  const { setPage } = useApplication();
  const { canCreateRole } = useAccessControl();
  const { utils } = useUtils();
  const [state, _setState] = useState({
    roles: [],
    loading: false,
    activeTab: 0,
    activeRole: 0,
    open: false,
  });
  const rolesListRef = React.useRef(null);

  const setState = (newState) => _setState({ ...state, ...newState });

  useEffect(() => {
    setPage({
      title: "Roles",
      path: "/app/roles",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetRoles = (roles) => {
    rolesListRef.current.setRoles(roles);
  }

  return (
    <>
      <PaperBlock
        title="Roles & Permissions"
        loading={state.loading}
        actions={
          <>
            {canCreateRole() && (
              <Button
                sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto' }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setState({ open: true })}
              >
                Create role
              </Button>
            )}
          </>
        }
      >
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          {/* <Tabs
            orientation="vertical"
            variant="scrollable"
            value={state.activeRole}
            onChange={(e, activeTab) => setState({ activeRole: activeTab })}
            aria-label="Roles And Permissions"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab sx={{}} label="Roles" {...a11yProps(0)} />
            <Tab label="Permissions" {...a11yProps(1)} />
          </Tabs>
          <TabPanel
            value={state.activeRole}
            index={0}
            style={{ width: "100%" }}
          >
            <RolesList />
          </TabPanel>
          <TabPanel value={state.activeRole} index={1}>
            <PermissionsList />
          </TabPanel> */}
          {utils?.permissions?.length > 0 && (
            <RolesList ref={rolesListRef} />
          )}
        </Box>
        <RolesManagementDialog
          roles={rolesListRef?.current?.roles || []}
          setRoles={handleSetRoles}
          open={state.open}
          onClose={() => setState({ open: false })}
        />
      </PaperBlock>
    </>
  );
};

export default Roles;
