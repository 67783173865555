import {
  CircularProgress,
  Box
} from "@mui/material";
import { useEffect } from "react";
import useMembershipshipAllCallLog from "../../hooks/useAllCallLogs";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./components/AllcallLogColumn";

export default function AllCalllog({
  fileable_type,
  fileable_id
}) {
  const {
    getMemberships,
    membershipAllCallLogReducer: {
      results,
      query,
      meta,
      loading
    },
    changePage,
    changePerPage,
  } = useMembershipshipAllCallLog();

  useEffect(() => {
    getMemberships()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      perPage: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getMemberships({
      search: query,
      page: meta?.current_page,
      perPage: parseInt(event.target.value),
    });
  };

  const allCallLogResults = results?.map((contractor) => ({
    id: contractor?.id,
    name: contractor?.from?.name ?? "Unknown",
    phoneNumber: contractor?.from?.phoneNumber,
    result: contractor?.result,
    duration: contractor?.duration,
    startTime: contractor?.startTime,
  }));

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={allCallLogResults}
            pageSize={Number(meta?.per_page)}
            rowsPerPageOptions={[Number(meta?.per_page)]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      )}
    </>
  )

}