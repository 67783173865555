import { Chip } from "@mui/material";
import { callStausColors } from "utils/queue";

const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
        field: 'phoneNumber',
        headerName: 'Phone',
        width: 350,
        editable: true,
    },
    {
        field: 'result',
        headerName: 'Status',
        width: 350,
        editable: true,
        renderCell: (params) => (
            <Chip
                sx={{
                    mt: 1,
                    justifyContent: "flex-start",
                    background: callStausColors[params.value],
                    color: "#fff",
                }}
                label={params.value}
            />
        ),
    },
    {
        field: 'duration',
        headerName: 'Duration	',
        sortable: false,
        width: 350,
    },
    {
        field: 'startTime',
        headerName: 'Date	',
        sortable: false,
        width: 250,
        valueFormatter: (params) => {
            const date = new Date(params.value);
            return date.toLocaleString();
        },
    },
];
export default columns;