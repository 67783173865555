import {
  SEARCH_TASK,
  QUEUE_SET_DATA,
  CHANGE_TASK_STATUS,
  CHANGE_TASK_USER,
  QUEUE_CHANGE_PROP,
  QUEUE_CHANGE_PROP_MULTIPLE,
  QUEUE_VIEW_TASK,
  CHANGE_TASK_VALUE,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  NEW_TASK,
  CLOSED_TASKS,
  MY_TASKS,
} from "redux/actions/taskActions";

const initialState = {
  loading: true,
  search: "",
  results: [],
  openTask:[],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
  range: [null, null],
  viewTask: {},
};

export default function queue(state = initialState, action = {}) {
  switch (action.type) {
    case QUEUE_SET_DATA: {
      const newState = { ...state };
      newState.loading = false;
      newState.results = action?.response?.result;
      newState.meta = action?.response?.meta;
      return newState;
    }
    case QUEUE_CHANGE_PROP:
      const NewState = { ...state };
      NewState[action.prop] = action.value;
      return NewState;
    case QUEUE_CHANGE_PROP_MULTIPLE:
      return { ...state, ...action.values };
    case QUEUE_VIEW_TASK:
      return { ...state, viewTask: action.viewTask };
    case CHANGE_TASK_STATUS: {
      const newState = { ...state };
      // eslint-disable-next-line eqeqeq
      const taskIndex = state.results.findIndex((t) => t.id == action.id);
      if (taskIndex !== -1) {
        newState.results[taskIndex].status = action.status;
      }
      return newState;
    }
    case CHANGE_TASK_USER: {
      const newState = { ...state };
      // eslint-disable-next-line eqeqeq
      const taskIndex = state.results.findIndex((t) => t.id == action.id);
      if (taskIndex !== -1) {
        newState.results[taskIndex].assigned_to = action.user_id;
      }
      return newState;
    }
    case CHANGE_TASK_VALUE: {
      const newState = { ...state };
      // eslint-disable-next-line eqeqeq
      const taskIndex = state.results.findIndex((t) => t.id == action.id);
      if (taskIndex !== -1) {
        newState.results[taskIndex][action.field] = action.value;
      }
      return newState;
    }
    case NEW_TASK: {
      return {
        ...state,
        openTask: action?.results.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }
    case CLOSED_TASKS: {
      return {
        ...state,
        closedTask: action?.results.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }

    // Fix meta later by adding it to the API and adding pagination to this API query
    case MY_TASKS: {

      return {
        ...state,
        myTask: action?.results,
        loading: false,
        meta: {
          per_page: 10,
          current_page: 1,
        },
      };
    }
    case SEARCH_TASK: {
      return {
        ...state,
        search: action.search,
        loading: true,
        meta: {
          ...state.meta,
          per_page: action.perPage,
          current_page: action.page,
        },
      };
    }
    case CHANGE_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    }
    case CHANGE_PER_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    }
    default:
      return state;
  }
}
