import React, { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContractorSelect from "components/ContractorSelect";
import FormField from "components/FormField";
import { jobDispatchRequest } from "api/jobs";
import Toast from "components/Toast";

const Dispatched = ({ job, removeContractor, onSubmit }) => {
  const [dispatchTo, setDispatchTo] = useState({
    contractor: {},
    loading: false,
  });
  const setState = (values) => setDispatchTo({ ...dispatchTo, ...values });
  const { contractor } = job;

  const handleSubmit = () => {
    jobDispatchRequest(job.id, {
      contractor_id: dispatchTo.contractor.id,
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "Job dispatch request submitted",
      });
      onSubmit && onSubmit(res.data);
    });
  };

  if (contractor) {
    return (
      <Card>
        <CardContent>
          <Typography gutterBottom variant="subtitle2" component="div">
            {contractor.name}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            Email:{" "}
            <span>{contractor.email}</span>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${contractor.email}`}
            >
              {contractor.email}
            </a> */}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            Phone:{" "}
            <span>{contractor.phone}</span>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href={`tel:${contractor.phone}`}
            >
              {contractor.phone}
            </a> */}
          </Typography>
          <Divider />
          <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={removeContractor}
              startIcon={<DeleteIcon />}
            >
              Remove Contractor
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box>
      <ContractorSelect
        handleChange={(val) => setState({ contractor: val || {} })}
        selected={dispatchTo.contractor.id}
      />
      <Box mt={1}>
        <FormField placeholder="add note" multiline="true" />
      </Box>
      <Box mt={1} textAlign="center">
        <Button
          onClick={handleSubmit}
          disabled={!dispatchTo.contractor?.id || dispatchTo.loading}
          role="submit"
          type="submit"
          variant="contained"
          color="warning"
        >
          {dispatchTo.loading ? "Please wait" : "Send Dispatch Request"}
        </Button>
      </Box>
    </Box>
  );
};

export default Dispatched;
