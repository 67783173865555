import React, { useState, useEffect, useRef, useMemo } from 'react';
import useQueue from 'hooks/useQueue';
import TaskQueueTab from './TaskQueueTab';

const TaskQueueClosed = () => {
  const [loading, setLoading] = useState(false);
  const {
    queue: { closedTask, meta, query: queueQuery },
    getClosedTasks,
    changeTask,
    changeTaskUser,
  } = useQueue();

  const query = useMemo(() => queueQuery, [queueQuery]);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      const fetchTasks = async () => {
        setLoading(true);
        try {
          await getClosedTasks(query);
        } finally {
          setLoading(false);
          hasFetched.current = true;  // Ensure we fetch only once
        }
      };
      fetchTasks();
    }
  }, []); 

  const memoizedProps = useMemo(() => ({
    fetchTasks: getClosedTasks,
    taskData: closedTask,
    meta,
    query,
    changeTask,
    changeTaskUser,
    loading,
  }), [getClosedTasks, closedTask, meta, query, changeTask, changeTaskUser, loading]);

  return (
    <TaskQueueTab {...memoizedProps} />
  );
};

export default TaskQueueClosed;
