import React, { useEffect } from "react";
import {
  CircularProgress,
  Button
} from "@mui/material";
import Swal from "sweetalert2";
import useMembership from "../../../../../hooks/useMembership";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./DeleteMemberColumnData";
import useStyles from "components/TaskPreview/styles-jss";

export default function DeletedMembers() {
  const classes = useStyles();
  const {
    membershipReducer: { query, meta, deletedMembers, loading },
    changePage,
    changePerPage,
    getDeletedMembers,
    restoreDeletedMembers
  } = useMembership();

  useEffect(() => {
    getDeletedMembers(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getDeletedMembers({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getDeletedMembers({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  // Deleted member restoration
  const deleteMemeberHandler = (id) => {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      title: `Are you sure want to restore Member with ID: ${id}?`,
    }).then(res => {
      if (res.isConfirmed) {
        restoreDeletedMembers({ id: id }).then(res => {
          if (res) {
            Swal.fire({
              toast: true,
              timer: 3000,
              title: 'Record Restored',
              showConfirmButton: false,
              icon: "success"
            })
          } else {
            Swal.fire({
              toast: true,
              position: "top-right",
              text: 'Member Restored Successfully!',
              icon: "success",
              timer: 4000,
              showConfirmButton: false
            })
          }
        }).catch(error => {
          Swal.fire({
            icon: "error",
            text: 'Member restore unsuccessful',
            confirmButtonText: 'OK'
          })
        });
      }
    })
  }

  const DeleteMemberResults = deletedMembers?.map((deleteMemberData) => ({
    id: deleteMemberData.id,
    name: deleteMemberData.name,
    address: deleteMemberData.address,
    cell_phone: deleteMemberData.cell_phone,
    email: deleteMemberData.email,
    status: deleteMemberData.status,
    membership_id: deleteMemberData.membership_id
  }));

  const renderAction = (data) => (
    <div>
      {!["DO NOT RENEW"].includes(data?.row?.status) ?
        <Button
          sx={{ whiteSpace: "nowrap" }}
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteMemeberHandler(data?.id)}
        >
          Restore Member
        </Button> :
        ""
      }
    </div>
  );

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <DataGrid
            columns={columns(renderAction)}
            rows={DeleteMemberResults}
            pageSize={Number(meta?.per_page)}
            rowsPerPageOptions={[Number(meta?.per_page)]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
            className={classes.dataGrid}
          />
        </>
      )}
    </>
  );
}
