import {
  SEARCH_PAYMENTS_RESULTS,
} from "redux/actions/paymentsActions";

const initialState = {
  loading: true,
  search: "",
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

export default function payments(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_PAYMENTS_RESULTS:
      return {
        ...state,
        results: action.results.data,
        meta: action.results,
        loading: false,
      };
    // case SEARCH_PAYMENTS_LOADING:
    //   return { ...state, loading: action.loading };
    // case SEARCH_PAYMENTS:
    //   return {
    //     ...state,
    //     search: action.query.search,
    //     per_page: action.query.per_page,
    //     current_page: action.query.current_page,
    //     loading: true
    //   };
    // case CHANGE_PAGE:
    //   return {
    //     ...state,
    //     meta: {
    //       ...state.meta,
    //       current_page: action.page,
    //     },
    //   };
    // case CHANGE_PER_PAGE:
    //   return {
    //     ...state,
    //     meta: {
    //       ...state.meta,
    //       per_page: action.perPage,
    //     },
    //   };
    default:
      return state;
  }
}
