import {
    CHANGE_PER_PAGE_VOICE_MAIL,
    SEARCH_MEMBERS_VOICE_MAIL,
    SEARCH_MEMBERS_LOADING_VOICE_MAIL,
    SEARCH_MEMBERS_RESULTS_VOICE_MAIL,
    CHANGE_PAGE_VOICE_MAIL
} from "redux/actions/voiceCallMailAction";

const initialState = {
    loading: true,
    query: "",
    results: [],
    meta: {
        current_page: 1,
        from: null,
        last_page: 1,
        per_page: 10,
        to: null,
        total: 0,
    },
};

export default function voicecallMail(state = initialState, action = {}) {
    switch (action.type) {
        case SEARCH_MEMBERS_RESULTS_VOICE_MAIL:
            return {
                ...state,
                results: action.results?.results,
                accessToken:action.results?.accessToken,
                meta: action?.results?.meta,
                loading: false,
            };
        case SEARCH_MEMBERS_LOADING_VOICE_MAIL:
            return { ...state, loading: action.loading };
        case SEARCH_MEMBERS_VOICE_MAIL:
            return { ...state, query: action.query, loading: true };
        case CHANGE_PAGE_VOICE_MAIL:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    current_page: action.page,
                },
            };
        case CHANGE_PER_PAGE_VOICE_MAIL:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    per_page: action.perPage,
                },
            };
        default:
            return state;
    }
}