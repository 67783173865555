import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import React from 'react'

function CheckBlock({
	title,
	loading,
	actions,
	children
}) {
	return (
		<>
			<BreadCrumb />
			<Paper elevation={2} sx={{ p: 2, mt: 2 }} style={{ "overflow": "auto" }}>
				<Box className="main-Headbox_Custm" display="flex" justifyContent="space-between" alignItems="center" px={2}>
					<Typography
						variant="h5"
						component="h5"
						fontWeight="bold"
						color="primary"
					>
						{title}
					</Typography>
					<Box display="flex" alignItems="center" className="headSeach-custm" justifyContent="flex-end">
						{actions}
					</Box>
				</Box>
				<Box mt={4}>
					{loading && (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)}
					{!loading && children}
				</Box>
			</Paper>
		</>
	);
}

CheckBlock.defaultProps = {
	title: '',
	actions: null,
	children: null,
};
export default CheckBlock
