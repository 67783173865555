import React, { useEffect, useState } from "react";
import { Tab, Box, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import Member from "./components/Members";
import DeletedMembers from "./components/DeletedMembers";
import SearchInput from "components/SearchInput";
import useMembership from "hooks/useMembership";
import useAccessControl from "hooks/useAccessControl";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import NewMember from "./components/NewMember";
import { useLocation } from 'react-router-dom';

const Members = () => {

  const [tabValue, setTabValue] = useState("0");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.tabValue) {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);

  const { setPage } = useApplication();
  const { canCreateContractor } = useAccessControl();

  const {
    search,
    getMemberships,
    getOpenMember,
    getDeletedMembers,
    membershipReducer: { query, meta },
    changePage,
    changePerPage,
  } = useMembership();

  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Members Directory",
      path: "/app/members",
    });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search) => {
    getMemberships({
      search,
      per_page: meta?.per_page,
    });
    getOpenMember({
      search,
      per_page: meta?.per_page,
    });
    getDeletedMembers({
      search,
      per_page: meta?.per_page,
    })
  };

  return (
    <>
      <PaperBlock title="Members"
        actions={
          <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">
            <SearchInput
              defaultValue={query}
              onChange={handleSearch}
              placeholder="By ID, First Name, Last Name, Phone or Email"
            />
            {canCreateContractor() &&
              <Button
                sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto' }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/app/members/create")}
              >
                Add Member
              </Button>
            }
          </Box>
        }
      >
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab
                  label="Members"
                  value="0"
                />
                <Tab
                  label="New Members"
                  value="1"
                />
                <Tab
                  label="Canceled Members"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="0">
              <Member />
            </TabPanel>
            <TabPanel value="1">
              <NewMember />
            </TabPanel>
            <TabPanel value="2">
              <DeletedMembers />
            </TabPanel>
          </TabContext>
        </Box>
      </PaperBlock>
    </>
  );
};

export default Members;
