import { Chip } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { membershipColors } from "utils/membership";
import "../../../../../../src/components/Callincoming/index.css"

const CancelMembersLink = ({ params }) => {
  const navigate = useNavigate();
  const url = `/app/members/${params.id}`;
  const handleLinkClick = (event) => {
    event.preventDefault();
    navigate(url, { replace: true });
  };
  return (
    <NavLink to="/" onClick={handleLinkClick} className='link'>
      {params.value}
    </NavLink>
  );
};
const columns = (renderAction) => [
  {
    field: "id",
    headerName: "ID",
    sortable: true,
    type: "string",
    width: 150,
    renderCell: (params) => <CancelMembersLink params={params} />,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    type: "string",
    flex: 1,
    renderCell: (params) => <CancelMembersLink params={params} />,
  },
  {
    field: "address",
    headerName: "Address",
    sortable: true,
    type: "string",
    flex: 1,
  },
  {
    field: "cell_phone",
    headerName: "Phone",
    editable: false,
    type: "string",
    sortable: true,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    type: "string",
    flex: 1.2,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 1.2,
    renderCell: (params) => (
      <Chip
        sx={{
          background: membershipColors[params.value],
          color: "white",
        }}
        label={params.value}
      />
    ),
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    flex: 0.7,
    sortable: false,
    renderCell: renderAction,
  },
];
export default columns;