import React, { useState, Fragment, useEffect } from "react";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Chip,
  Divider,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import TaskPreview from "components/TaskPreview";
import JobRequestModal from "components/JobRequestModal";
import { getDashTasks } from "api/dashboard";
import { queueColors, taskTypeLabels } from "utils/queue";
import PaperBlock from "../../ContentBlock";
import styles from "./style-jss";

function TaskWidget(props) {
  const { classes, filterDate } = props;
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState(false);

  const [viewJob, setViewJob] = useState(false);
  const handleClose = () => setOpen(false);
  const changeOpenTask = (values) => setOpen({ ...open, ...values });

  const getTasks = () => {
    setLoading(true);
    getDashTasks({ from: filterDate[0], to: filterDate[1] }).then((res) => {
      setTasks(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getTasks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate]);

  const setJob = (job) => {
    setOpen(false);
    setViewJob(job);
  };

  const RenderTasks = () => {
    if (tasks.length === 0) {
      return (
        <Box textAlign="center">
          <Typography fontWeight="bold" fontStyle="italic" variant="body1">
            No Data
          </Typography>
        </Box>
      );
    }

    return tasks.map((value) => (
      <Fragment key={value.id}>
        <ListItem
          key={value}
          role={undefined}
          onClick={() => setOpen(value)}
          button
          className={classNames(classes.listItem)}
          
        >
          <Tooltip title={value.title}>
          <ListItemText
            primary={`#${value.id} ${value.title.length > 20 ? value.title.substring(0, 15) + "..." : value.title}`}
            secondary={`${taskTypeLabels[value.task_type]}`}
          />
            </Tooltip> 
          <ListItemSecondaryAction
          >
            <Chip
              sx={{
                mt: 1,
                justifyContent: "flex-start",
                background: queueColors[value.status],
                color: "#fff",
                font:0.2
                
              }}
              label={value.status}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </Fragment>
    ));
  };

  return (
    <PaperBlock
      title="My Tasks"
      icon="ion-ios-checkbox-outline"
      noMargin
      whiteBg
    >
      <List className={classes.taskList}>
        {loading ? (
          <Box justifyContent="center" alignItems="center" display="flex" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          RenderTasks()
        )}
      </List>
      {open && (
        <TaskPreview
          getTasks={getTasks}
          data={open}
          handleClose={handleClose}
          handleChange={changeOpenTask}
          setJob={setJob}
        />
      )}
      {viewJob && (
        <JobRequestModal
          open
          onRefresh={getTasks}
          viewJob={viewJob}
          membership_id={viewJob.membership_id}
          handleClose={() => setViewJob(false)}
        />
      )}
    </PaperBlock>
  );
}

export default withStyles(styles)(TaskWidget);
