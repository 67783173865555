import React from "react";
import { Button, Box } from "@mui/material";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import { changeStateRequest } from "api/membership";
import "../../../../../../utils/sweetalert.css"

const MemberApplicationApproval = ({
  changeMembershipState,
  membership_id,
}) => {
  const theme = useTheme();
  const approveMembership = () => {
    Swal.fire({
      title: "Do you want to approve this membership application?",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      customClass: {
        title: "swal-text",
        popup:"swal-text"
        
      },
      confirmButtonText: "Approve Application",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStateRequest(membership_id, { state: "ACCEPTED" }).then(() => {
          Swal.fire("Member Approved!", "");
          changeMembershipState("MEMBERSHIP");
        });
      }
    });
  };

  const cancelMembership = async () => {
    const { value: reason } = await Swal.fire({
      title: "Cancel Membership Application",
      text: "Are you sure you want to cancel this membership application?",
      input: "textarea",
      inputLabel: "Cancellation Reason",
      inputValue: "",
      customClass: {
        title: "swal-text",
        popup:"swal-text"
        
      },
      showCancelButton: true,
      confirmButtonColor: theme.palette.error.main,
      confirmButtonText: "Cancel Application",
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });

    if (reason) {
      Swal.fire(`Your cancellation reason is ${reason}`);
      changeStateRequest(membership_id, {
        state: "CANCELED",
        reason: reason,
      }).then(() => {
        Swal.fire("Member Cancelled!", "", "success");
        changeMembershipState("CANCELED", reason);
      });
    }
  };

  return (
    <Box display="flex">
      <Box pr={2}>
        <Button onClick={approveMembership} variant="contained" color="success">
          Accept Membership
        </Button>
      </Box>
      <Box pr={2}>
        <Button onClick={cancelMembership} variant="contained" color="error">
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default MemberApplicationApproval;
