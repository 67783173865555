
import {
    REMITTANCE_DATA_LOADING,
    REMITTANCE_DATA_RESULTS, 
    CHANGE_PAGE
  } from "redux/actions/reportActions";
  
  const initialState = {
    loading: false,
    query: "",
    remittance_data: [],
    meta: {
      current_page: 1,
      from: null,
      last_page: 1,
      per_page: 10,
      to: null,
      total: 0,
    },
  };
  
  export default function reports(state = initialState, action = {}) {  
    switch (action.type) {
      case REMITTANCE_DATA_RESULTS: {
        return {
          ...state,
          meta: action?.results?.meta,
          remittance_data: action?.results.result,
          loading: false,

        };
      }
      case CHANGE_PAGE: {
        return {
          ...state,
          meta: {
            ...state.meta,
            current_page: action.page,
          },
        };
      }
      case REMITTANCE_DATA_LOADING:
        return { ...state, loading: action.loading };
      default:
        return state;
    }
  }
  