import { useState, forwardRef, useImperativeHandle } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import FormField from "components/FormField";
import Swal from "sweetalert2";

const CreateDirectoryDialog = forwardRef(
  ({ createHandler, renameHandler, files }, ref) => {
    const [state, setState] = useState({
      open: false,
      label: "",
      uuid: null,
    });
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
      open: (fileUuid) => {
        if (fileUuid) {
          const selectedFile = files.find((file) => file.uuid === fileUuid);
          setState({
            open: true,
            label: selectedFile.label,
            uuid: fileUuid,
          });
          return;
        }
        setState({
          ...state,
          open: true,
        });
      },
    }));

    const handleClose = () => {
      setState({ open: false, label: "", uuid: null });
    };

    const submitHandler = () => {
      setLoading(true)
      let title = '';
      if (state.uuid) {
        title = 'File Renamed Successfully!';
        renameHandler(state.label, state.uuid);
      } else {
        title = 'Folder Created Successfully!';
        createHandler(state.label);
      }
      Swal.fire({
        toast: true,
        timer: 3000,
        position: "top-right",
        title: title,
        showConfirmButton: false,
        icon: "success"
      })
      handleClose();
    };

    return (
      <Dialog onClose={handleClose} open={state.open} fullWidth maxWidth="xs">
        <DialogTitle color="primary">
          {state.uuid ? "Rename" : "Create"}
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <FormField
              label="Name"
              onChange={(e) => setState({ ...state, label: e.target.value })}
              value={state.label}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submitHandler} autoFocus variant="contained">
            Submit
            {loading && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={16} />
              </Box>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default CreateDirectoryDialog;
