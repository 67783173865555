import React from "react";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import AddNew from "components/AddNew";
import PropertyUpdate from "components/MembershipForm/FormParts/PropertyUpdate";
import useMembership from "hooks/useMembership";
import ".././../../../../../utils/sweetalert.css"

const special = ["1st", "2nd", "3rd", "4th", "5th"];

const PropertiesList = ({ form, setForm, addProperty, index }) => {

  const theme = useTheme();
  const { deleteMembershipProperty } = useMembership();
  const handleRemoveProperty = async (propertyId, index) => {
    try {
      const membersData = [...form.properties]; // Create a copy to avoid direct mutation
      membersData.splice(index, 1);
      setForm({ properties: membersData }); // Update local state
      await deleteMembershipProperty({ id: propertyId }); // Await server-side deletion
      removePropertyById(propertyId); // Clean up client-side references
      Swal.fire({
        toast: true,
        timer: 3000,
        position: "top-right",
        title: "Property Deleted Successfully!",
        showConfirmButton: false,
        icon: "success",
      });
    } catch (error) {
      console.error("Failed to delete property:", error);
      Swal.fire({
        toast: true,
        timer: 3000,
        position: "top-right",
        title: "Property Deleted Successfully!",
        showConfirmButton: false,
        icon: "success",
        confirmButtonColor: theme.palette.primary.main,
      });
    }
  };

  const removePropertyById = (id) => {
    const properties = [...form.properties];
    const removeIndex = properties.findIndex(property => property.id === id);
    if (removeIndex) {
      properties.splice(removeIndex, 1);
      setForm({ properties });
    }
  };

  return (
    <Grid container pt={2} spacing={2}>
      {form.properties.map((property, index) => (
        <PropertyUpdate
          key={`property-${index}`}
          index={index}
          property={property}
          form={form}
          setForm={setForm}
          onRemove={() => handleRemoveProperty(property.id, index)}
          defaultLocation={{
            city: {
              id: form.properties[0].city.id,
              city: form.properties[0].city.label,
            },
            state: { code: form.properties[0].state },
            zip: form.properties[0].zip,
          }}
        />
      ))}
      {form.properties.length < 5 && (
        <Grid item md={12} sm={12} xs={12}>
          <AddNew
            onClick={addProperty}
            label={`Add ${special[form.properties.length]} Property Details`}
            nomargin
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PropertiesList;
